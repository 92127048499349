import * as React from "react";
import { Container, Typography } from "@mui/material";
import { Color } from "../../../../GlobalStyles";
// import ImageGrid from "../ImagesInfiniteScroll/ImagesInfiniteScroll";

export default function WhyChooseUs1() {
  const cardData = [
    {
      id: 1,
      title: "#1",
      subtitle: "in the MBA Training Field",
    },
    {
      id: 2,
      title: "12+ Years",
      subtitle: "of Experience",
    },
    {
      id: 3,
      title: "1,50,000+",
      subtitle: "Sessions Conducted",
    },
    {
      id: 4,
      title: "45,00,000+",
      subtitle: "Minutes of Personal Interation",
    },
  ];

  return (
    <div style={{ marginTop: "141px" }}>
      <Typography
        fontSize="24px"
        align="center"
        fontWeight={400}
        sx={{
          position: "relative",
          "&::after": {
            content: "''",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "-10%",
            width: "64px",
            borderBottom: "2px solid #561D5E",
          },
          paddingBottom: "9px",
        }}
      >
        Why choose us?
      </Typography>
      <Container
        sx={{
          display: "flex",
          gap: "64px",
          marginTop: "64px",
          width: "50%",
          flexWrap: "wrap",
        }}
      >
        {cardData.map(card => (
          <Container
            key={card.id}
            className="landing-container"
            sx={{
              width: "313px",
              height: "152px",
              justifyContent: "center", // center horizontally
              alignItems: "center", // center vertically
              // border: "1px lightgrey solid",
              borderRadius: "20px",
              boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.14)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "40px", color: Color.primary1 }}>
              {card.title}
            </p>
            <p style={{ fontSize: "15px", color: Color.neutralMidGrey }}>
              {card.subtitle}
            </p>
          </Container>
        ))}
      </Container>
    </div>
  );
}
