import React from "react";
import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import FaLayerGroup from "@mui/icons-material/FaLayerGroup";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import "../SideNavBar/SidenavBar.css";

const SideNavBarStudent = props => {
  // const services = new Service();
  const navigate = useNavigate();
  const path = window.location.pathname;

  //data
  // const [allowPlaceO, setAllowPlaceO] = useState(false);

  console.log(path);

  //allowed - Placement OPs(PlaceO)
  // useEffect(() => {
  //   handleMainEffect();
  // }, []);

  // const handleMainEffect = async () => {
  //   const allowPO_response = await getAllowPlaceO();
  //   setAllowPlaceO(allowPO_response);
  // };

  // const getAllowPlaceO = async () => {
  //   try {
  //     const response = await services.get(`/po/ca/poAllowedCheck`);
  //     console.log("getAllowPlaceO ~ response:", response);
  //     if (response) return response.po_allowed;
  //     else return false; //did this so that the page doesn't break or no anomaly happens
  //   } catch (error) {
  //     console.log("getAllowPlaceO ~ error:", error);
  //   }
  // };

  return (
    <div>
      <div className="area">
        <nav className="main-menu">
          <ul>
            <li>
              <a href="/">
                <div style={{ padding: "10px" }}>
                  <img
                    alt="logo"
                    src="Career_Carve_Logo__1_-removebg-preview 2.png"
                    style={{
                      margin: "17px 6px 75px 12px",
                      marginLeft: "6%",
                      width: "fit-content",
                      height: "50px",
                      float: "left",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </a>
            </li>
          </ul>
          <ul>
            {/* <li class="has-subnav">
              <button onClick={() => navigate("/StudentDashboard")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/StudentDashboard"
                      ? "sidenav-image-a-dashboard"
                      : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <HomeOutlinedIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Dashboard</span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li> */}

            <li class="has-subnav">
              <button onClick={() => navigate("/StudentActivity")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/StudentActivity"
                      ? "sidenav-image-a-dashboard"
                      : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <InsertInvitationOutlinedIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Activity </span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li>

            {/* <li class="has-subnav">
              <button onClick={() => navigate("/StudentGroupSession")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/StudentGroupSession"
                      ? "sidenav-image-a-dashboard"
                      : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <HistoryIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Group Session</span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li> */}

            {/* <li class="has-subnav">
              <button onClick={() => navigate("/Assessment")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/Assessment" ? "sidenav-image-a-dashboard" : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <AssignmentIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Assessment</span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li> */}

            {/* {allowPlaceO ? (
              <li class="has-subnav">
                <button onClick={() => navigate("/Profile")}>
                  <div
                    style={{ display: "flex", width: "100%" }}
                    className={
                      path === "/Profile" ? "sidenav-image-a-dashboard" : ""
                    }
                  >
                    <ListItemButton style={{ padding: "0px" }}>
                      <div
                        style={{
                          padding: "15px",
                          margin: 10,
                          marginRight: 10,
                          paddingRight: "29px",
                          marginTop: 0,
                          marginBottom: 0,
                          borderRight: "none",
                        }}
                      >
                        <PersonIcon style={{ width: "30px", height: "30px" }} />
                      </div>
                      <div
                        style={{ padding: "10px 0px 10px 0px", width: "80%" }}
                      >
                        <span class="nav-text">Profile</span>
                      </div>
                    </ListItemButton>
                  </div>
                </button>
              </li>
            ) : (
              <></>
            )} */}
          </ul>

          {/* <ul className="logout" onClick={e => handleLogout(e)}>
            <li className="has-subnav">
              <a className="sidenav-image-a">
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{ padding: "18px 0px 18px 30px", width: "100px" }}
                  >
                    <i className="fa fa-power-off"></i>
                  </div>
                  <div style={{ padding: "14px 0px 9px 0px", width: "80%" }}>
                    <span className="nav-text">Logout</span>
                  </div>
                </div>
              </a>
            </li>
          </ul> */}
        </nav>
      </div>
    </div>
  );
};

export default SideNavBarStudent;
