import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import * as posenet from "@tensorflow-models/posenet";
import * as tf from "@tensorflow/tfjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { Color, DivCenter } from "../../../GlobalStyles";
import { ellipsisFunction } from "../../services/ellipsisFunction";
import Service from "../../services/httpService";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
export const suffleArray = array => {
  let temp_array = [...array];
  for (let i = temp_array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [temp_array[i], temp_array[j]] = [temp_array[j], temp_array[i]];
  }

  return temp_array;
};

function Questions() {
  //CLEAR RESPONSE
  //SUBMIT BEFORE TIme
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const location = useLocation();
  const testType = location.state.testType;

  const services = new Service();
  const { test_id } = useParams();

  const [seconds, setSeconds] = useState(3000);

  const memoizedSeconds = useMemo(() => seconds, [seconds]);
  const [fullScreen, setFullScreen] = useState(false); //OPEN ~ change value to false
  const webcamRef = useRef(null);
  const [allQuestions, setAllQuestions] = useState([]);

  const [scenarioAllQuestions, setScenarioAllQuestions] = useState([
    ...allQuestions,
  ]);

  const [errorText, setErrorText] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState(0);
  const [cameraModal, setCameraModal] = useState(false);
  const [endModal, setEndModal] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(true); // CHECK WHETHER TO SHOW SKIP MODAL OR DIRECTLY SKIP THE QUESTION
  const [submitModal, setSubmitModal] = useState(false); // CHECK WHETHER TO
  const [senarioQuestionsCount, setSenarioQuestionCount] = useState(0);
  const [tabSwitchingModal, setTabSwitchingModal] = useState(false);
  const [testDesqualifyModal, setTestDesqualifyModal] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [testTerminationMessage, setTestTerminationMessage] = useState(
    "Your assessment has been automatically terminated due to repeated tab switching"
  );
  const handleOptionsSelect = (e, option, option_index) => {
    e.preventDefault();
    let temp_allQuestions = allQuestions;
    temp_allQuestions[selectedQuestions].res_index = [option.key];
    temp_allQuestions[selectedQuestions].type = "attempted";
    setAllQuestions(temp_allQuestions);
  };
  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const personCount = useRef(0);
  const cameraModalRef = useRef(false);

  //TIMER
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      } else {
        endTest();
        clearInterval(interval);
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [seconds]);

  const runPoseEstimation = async () => {
    if (!cameraModalRef.current) {
      try {
        tf.setBackend("webgl");
        const net = await posenet.load({
          architecture: "ResNet50",
          outputStride: 16,
          inputResolution: { width: 100, height: 100 },
          quantBytes: 2,
        });
        const webcam = webcamRef.current.video;

        const pose = await net.estimateMultiplePoses(webcam, {
          flipHorizontal: true,
          outputStride: 16,
          maxDetections: 2,
          scoreThreshold: 0.0,
        });
        let count = 0;

        pose.map(person => {
          if (
            person.score > 0.3 ||
            (person.keypoints[0].score > 0.4 &&
              person.keypoints[1].score > 0.4 &&
              person.keypoints[2].score > 0.4 &&
              person.score > 0.15)
          ) {
            count = count + 1;
          }
        });

        pose.forEach(singlePose => {
          singlePose.keypoints.forEach(keypoint => {
            tf.dispose(keypoint.part);
          });
          tf.dispose(singlePose.keypoints);
        });

        if (count > 1) {
          if (personCount.current > 4) {
            setTestDesqualifyModal(true);
            setTestTerminationMessage(
              "Your assessment has been automatically terminated due to repeated violations with face detection. Please ensure your webcam is working properly and positioned correctly for future attempts."
            );
          } else {
            setCameraModal(true); //OPEN
            cameraModalRef.current = true;
            personCount.current = personCount.current + 1;
            setErrorText(
              "Multiple people detected. Please ensure only one person is visible in the webcam frame to continue the assessment. Repeated violation will automatically end the test"
            );
          }
        } else if (count === 0) {
          if (personCount.current > 4) {
            setTestDesqualifyModal(true);
            setTestTerminationMessage(
              "Your assessment has been automatically terminated due to repeated violations with face detection. Please ensure your webcam is working properly and positioned correctly for future attempts."
            );
          } else {
            setCameraModal(true); //OPEN
            cameraModalRef.current = true;
            personCount.current = personCount.current + 1;

            setErrorText(
              "Your face cannot be seen clearly. Please adjust your webcam position to ensure your face is centered in the frame"
            );
          }
        } else {
          // User is sitting in front of the camera
          console.log("User is sitting in front of the camera.");
        }

        tf.dispose(pose);
        tf.dispose(net);
        tf.memory().numTensors; // Check the number of tensors (optional)
        tf.memory().dispose(); // Dispose all tensors
      } catch (e) {
        console.log("SOME ERROR OCCURED", e);
      }
    } else {
    }
  };

  //FACE RECOGNITION
  useEffect(() => {
    const intervalId = setInterval(runPoseEstimation, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // OPEN
  //TAB SWITCH AND PREVENT COPY PASTE
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setCount(count + 1);
        // The page is not visible (user switched tabs or windows)
        // setCount(count - 1);
      } else {
        // The page is visible (user switched back to this tab or window)
        // setCount(count + 10)
        if (count < 3) {
          setTabSwitchingModal(true);
        } else {
          setTestDesqualifyModal(true);
          setTestTerminationMessage(
            "Your assessment has been automatically terminated due to repeated tab switching"
          );
        }
      }
    };

    const handleCopy = e => {
      e.preventDefault();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("copy", e => handleCopy(e));
    document.addEventListener("keydown", event => {
      // Prevent common keyboard shortcuts
      if (
        (event.ctrlKey && event.key === "c") ||
        (event.ctrlKey && event.key === "x") ||
        (event.ctrlKey && event.key === "v") ||
        (event.ctrlKey && event.key === "a") ||
        (event.ctrlKey && event.key === "s") ||
        (event.ctrlKey && event.key === "p") ||
        (event.ctrlKey && event.key === "u") ||
        (event.ctrlKey && event.shiftKey && event.key === "n")
      ) {
        event.preventDefault();
      } else if (
        event.key === "PrintScreen" ||
        (event.key === "PrintScreen" && event.altKey)
      ) {
        event.preventDefault();
        alert("Screenshots are not allowed in this application.");
      } else if (event.key === "Escape") {
        event.preventDefault();
        console.log("Escape Key Pressed keypressed", event.key);
      } else {
        console.log("keypressed", event.key);
      }
    });

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [count]);

  const handleQuestions = (data, test_type_id) => {
    let question_data = JSON.parse(data[0].questions);
    if (parseInt(test_type_id) === 5) {
      let temp_questions = question_data.questions.map(item => {
        return {
          scenario_que: item.que,
          scenario_que_type: item.question_type,
          questions: suffleArray(
            item.questions.map(question => {
              return {
                options: [
                  {
                    opt_desc: question.opt_0,
                    opt_value: null,
                  },
                  {
                    opt_desc: question.opt_1,
                    opt_value: null,
                  },
                  {
                    opt_desc: question.opt_2,
                    opt_value: null,
                  },
                  {
                    opt_desc: question.opt_3,
                    opt_value: null,
                  },
                ],
                q_index: question.index,
                question: question.question,
                res_index: [],
                type: "skipped",
              };
            })
          ),
        };
      });

      let count = 0;
      temp_questions.map(item => (count = count + item.questions.length));

      temp_questions = suffleArray(temp_questions);

      console.log("QUESTIONS HETTING SUFFLED HERE", temp_questions);
      setAllQuestions(temp_questions);
      setSenarioQuestionCount(count);
      setScenarioAllQuestions(temp_questions);
    } else {
      let temp_questions = question_data.questions.map(item => {
        let aval_options = Object.keys(item);
        aval_options = aval_options.filter(item => item.startsWith("opt"));
        let new_options = aval_options.map(option => {
          return {
            options: item[option],
            key: option,
          };
        });

        new_options = suffleArray(new_options);

        return {
          que: item.que,
          options: [item.opt_0, item.opt_1, item.opt_2, item.opt_3],
          allOptions: new_options,
          q_index: item.index,
          res_index: [],
          type: "skipped",
        };
      });

      setAllQuestions(temp_questions);
    }
  };

  const handleDuration = (duration, startTime = null) => {
    if (startTime !== null) {
      const currentTimeEpoch = new Date();
      const originalDate = new Date(parseInt(startTime));

      // Step 2: Add 20 minutes to the Date object
      const endTime = new Date(originalDate.getTime() + 20 * 60000);
      const difference = endTime - currentTimeEpoch;
      const differneceInMinutes = Math.floor(difference / (1000 * 60));

      if (differneceInMinutes < 0) {
        endTest();
      } else {
        setSeconds(differneceInMinutes * 60);
      }
    } else {
      setSeconds(duration * 60);
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Escape") {
      event.preventDefault();
      // Your logic here
    }
  };

  // Attach the event listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  //GET QUESTIONS
  useEffect(() => {
    const getTestQuestions = async () => {
      // console.log("PREVIOUS DATA", localData);
      const localData = localStorage.getItem(`questions${test_id}`);
      const questions = await services.get(
        `tests/getQuestions?test_id=${test_id}`
      );

      handleDuration(questions.data[0].duration, questions.startTime);

      if ([null, undefined, ""].includes(localData)) {
        handleQuestions(questions.data, testType.test_type_id);
      } else {
        let parsedData = JSON.parse(localData);
        setAllQuestions(parsedData.allQuestions);
        setSelectedQuestions(parsedData.lastQuestion);
      }
    };

    getTestQuestions();
  }, []);
  //OPEN
  const openFullScreen = async () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      try {
        await elem.requestFullscreen();
        setFullScreen(true);
      } catch (error) {
        setFullScreen(false);
      }
    } else if (elem.webkitRequestFullscreen) {
      try {
        await elem.webkitRequestFullscreen();
        setFullScreen(true);
      } catch (error) {
        setFullScreen(false);
      }
    } else if (elem.msRequestFullscreen) {
      try {
        await elem.msRequestFullscreen();
        setFullScreen(true);
      } catch (error) {
        setFullScreen(false);
      }
    }
  };

  //Handle FULLSCREN
  useEffect(() => {
    const handleFullScreen = () => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
        openFullScreen();
      }
    };
    document.addEventListener("fullscreenchange", handleFullScreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreen);
    };
  }, []);

  const handleNext = () => {
    if (selectedQuestions < allQuestions.length - 1) {
      setSelectedQuestions(prev => prev + 1);
      let storeData = {
        lastQuestion: selectedQuestions,
        allQuestions: allQuestions,
      };
      localStorage.setItem(`questions${test_id}`, JSON.stringify(storeData));
    } else {
      endTest();
    }
  };

  const endTest = async () => {
    let postData = [];
    if (testType.test_type_id === 5) {
      postData = scenarioAllQuestions.map(scenario => {
        return scenario.questions.map(question => {
          const response = question.options.reduce(
            (acc, option_item, option_index) => {
              if (option_item.opt_value !== null) {
                acc[`opt_${option_index}`] = option_item.opt_value;
              }
              return acc;
            },
            {}
          );

          return {
            q_index: question.q_index,
            question_type: scenario.scenario_que_type,
            type: question.type,
            response: response, // Wrap the response in an array if needed
          };
        });
      });
    } else {
      postData = allQuestions.map(question => {
        return {
          q_index: question.q_index,
          res_index: question.res_index,
          type: question.type,
        };
      });
    }
    let body = {
      test_id: test_id,
      responseBody: postData.flat(),
    };
    const postAnswer = await services.post("/tests/testResponse", body);

    document.exitFullscreen();
    localStorage.removeItem(`questions${test_id}`);

    navigate("/Endtest", {
      state: {
        responseData: postAnswer.data,
        test_id: test_id,
        testType: testType,
      },
    });
    // window.close();
  };

  const handleClearResponse = e => {
    e.preventDefault();
    let temp_all_question = allQuestions.map((item, index) => {
      if (index === selectedQuestions) {
        return {
          ...item,
          type: "skipped",
          res_index: [],
        };
      } else {
        return item;
      }
    });

    setAllQuestions(temp_all_question);
  };

  const handleSkipModal = () => {
    if (showSkipModal) {
      setSkipModal(true);
    } else {
      if (selectedQuestions === allQuestions.length - 1) {
        setSubmitModal(true);
      } else {
        handleNext();
      }
    }
  };

  const [pageHandler, setPageHandler] = useState({
    scenario_index: 0,
    question_index: null,
  });

  const handleScenarioNext = (scenario_index, question_index, flag) => {
    if (![null, undefined, "null", "undefined"].includes(question_index)) {
      if (
        allQuestions[pageHandler.scenario_index].questions.length - 1 ===
        question_index
      )
        if (
          scenario_index === allQuestions.length - 1 &&
          question_index === allQuestions[scenario_index].questions.length - 1
        ) {
          endTest();
        } else
          setPageHandler(prev => ({
            ...prev,
            scenario_index: prev.scenario_index + 1,
            question_index: null,
          }));
      else
        setPageHandler(prev => ({
          ...prev,
          scenario_index: scenario_index,
          question_index: question_index + 1,
        }));
      setSenarioQuestionCount(prev => prev - 1);
    } else {
      setPageHandler(prev => ({
        ...prev,
        scenario_index: scenario_index,
        question_index: 0,
      }));
    }
    if (flag) {
      let temp_data = [...scenarioAllQuestions];
      temp_data[scenario_index].questions[question_index].type = "skipped";
      setScenarioAllQuestions(temp_data);
    }

    setReadMore(false);
  };
  function ScenarioPage() {
    const handleScenarioSkipModal = (scenario_index, question_index) => {
      if (showSkipModal) {
        setSkipModal(true);
      } else {
        handleScenarioNext(scenario_index, question_index);
      }

      setReadMore(false);
    };

    const handleLikelyChange = ({ target }, option_index) => {
      const { value } = target;
      let temp_scenario_data = [...scenarioAllQuestions];
      const end_value =
        temp_scenario_data[pageHandler.scenario_index].questions[
          pageHandler.question_index
        ].options.length - 1;

      if (parseInt(value) === end_value) {
        let json = temp_scenario_data[pageHandler.scenario_index].questions[
          pageHandler.question_index
        ].options.find(option => option.opt_value === end_value);
        if (json) json.opt_value = null;
      } else if (parseInt(value) === 0) {
        let json = temp_scenario_data[pageHandler.scenario_index].questions[
          pageHandler.question_index
        ].options.find(option => option.opt_value === 0);
        if (json) json.opt_value = null;
      }
      temp_scenario_data[pageHandler.scenario_index].questions[
        pageHandler.question_index
      ].options[option_index].opt_value = parseInt(value);
      temp_scenario_data[pageHandler.scenario_index].questions[
        pageHandler.question_index
      ].type = "attempted";
      setScenarioAllQuestions(temp_scenario_data);
    };

    return (
      <>
        {scenarioAllQuestions &&
          scenarioAllQuestions.map((scenario_item, scenario_index) =>
            ![null, undefined, "null", "undefined"].includes(
              pageHandler.question_index
            ) ? (
              scenario_item.questions.map((question_item, question_index) =>
                scenario_index === pageHandler.scenario_index &&
                question_index === pageHandler.question_index ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography>
                      Scenario -{" "}
                      {scenario_item.scenario_que.length > 145 && !readMore ? (
                        <Typography>
                          {ellipsisFunction(scenario_item.scenario_que, 145)}
                          <Typography
                            sx={{
                              color: Color.neutralMidGrey,
                              fontSize: "14px",
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => setReadMore(true)}
                          >
                            Read More
                          </Typography>
                        </Typography>
                      ) : (
                        <Typography>
                          {ellipsisFunction(scenario_item.scenario_que, null)}
                          <Typography
                            sx={{
                              color: Color.neutralMidGrey,
                              fontSize: "14px",
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => setReadMore(false)}
                          >
                            Read Less
                          </Typography>
                        </Typography>
                      )}
                    </Typography>

                    <hr
                      style={{
                        color: Color.neutralMidGrey,
                        margin: "32px 0 32px 0",
                      }}
                    />

                    <Typography
                      style={{
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {question_index + 1}. {question_item.question}
                    </Typography>
                    <Typography
                      style={{
                        color: Color.neutralMidGrey,
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0.5px",
                        mb: "32px",
                      }}
                    >
                      Select the most and least likely outcome
                    </Typography>
                    <Box sx={{ ...DivCenter, width: "100%", mb: "32px" }}>
                      <Typography
                        sx={{ ...DivCenter, width: "80%" }}
                      ></Typography>
                      <Typography
                        sx={{ ...DivCenter, width: "10%", fontWeight: "bold" }}
                      >
                        Most Likely
                      </Typography>
                      <Typography
                        sx={{ ...DivCenter, width: "10%", fontWeight: "bold" }}
                      >
                        Least Likely
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                        mb: "32px",
                      }}
                    >
                      {question_item.options.map(
                        (option_item, option_index) => (
                          <Box sx={{ ...DivCenter, width: "100%" }}>
                            <Typography
                              sx={{
                                ...DivCenter,
                                width: "80%",
                                justifyContent: "flex-start",
                                fontSize: "16px",
                              }}
                            >
                              <span style={{ color: Color.primary1 }}>
                                {option_index + 1}.
                              </span>{" "}
                              <span style={{ marginLeft: "5px" }}>
                                {option_item.opt_desc}
                              </span>
                            </Typography>
                            {/* <Box sx={{ ...DivCenter, width: "10%" }}><Radio sx={{
                            ...DivCenter,
                            width: '24px',
                            // height: '24px'
                          }} value={option_item.opt_value}></Radio></Box>
                          <Box sx={{ ...DivCenter, width: "10%" }}><Radio sx={{
                            ...DivCenter,
                            width: '24px',
                            // height: '24px'
                          }} value={option_item.opt_value}></Radio></Box> */}
                            {/* <FormControl sx={{ ...DivCenter, width: '20%' }}>
                            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}

                            <Box
                              id="box__radioButtons"
                              sx={{ ...DivCenter, width: "20%" }}
                            >
                              <RadioGroup
                                sx={{
                                  ...DivCenter,
                                  width: "100%",
                                  justifyContent: "space-around",
                                }}
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={option_item.opt_value}
                                onChange={e =>
                                  handleLikelyChange(e, option_index)
                                }
                                // defaultValue="female"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value={0}
                                  control={<Radio />}
                                  sx={{ ...DivCenter, width: "40%" }}
                                />
                                <FormControlLabel
                                  value={question_item.options.length - 1}
                                  control={<Radio />}
                                  sx={{ ...DivCenter, width: "40%" }}
                                />
                              </RadioGroup>
                              {/* </FormControl> */}
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                    <Box
                      sx={{
                        ...DivCenter,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "33.33%" }}>
                        <button
                          style={{
                            border: "1px solid #561D5E",
                            padding: "12px 80px",
                            fontSize: "20px",
                            borderRadius: "20px",
                          }}
                          onClick={() =>
                            handleScenarioSkipModal(
                              scenario_index,
                              question_index
                            )
                          }
                        >
                          Skip
                        </button>
                      </Box>
                      {/* End Test */}
                      <Box
                        sx={{
                          ...DivCenter,
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "20px",
                          width: "33.33%",
                        }}
                      >
                        <Typography sx={{ fontSize: "16px", color: "#969696" }}>
                          <span style={{ fontSize: "24px", color: "#561D5E" }}>
                            {formatTime(memoizedSeconds)}
                          </span>{" "}
                          for{" "}
                          <span style={{ color: "#561D5E" }}>
                            {senarioQuestionsCount - 1}{" "}
                          </span>
                          more questions
                        </Typography>
                        <Typography
                          onClick={() => setEndModal(true)}
                          sx={{
                            color: "red",
                            fontWeight: "500",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          End Test
                        </Typography>
                      </Box>
                      {/* Next Button */}
                      <Box
                        sx={{
                          ...DivCenter,
                          alignItems: "center",
                          justifyContent: "flex-end",
                          width: "33.33%",
                        }}
                      >
                        <button
                          disabled={
                            !(
                              question_item.options.filter(
                                option => option.opt_value !== null
                              ).length === 2
                            )
                          }
                          style={{
                            background: !(
                              question_item.options.filter(
                                option => option.opt_value !== null
                              ).length === 2
                            )
                              ? "#E6E6E6"
                              : "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                            padding: "12px 80px",
                            fontSize: "20px",
                            borderRadius: "20px",
                            color: "#fff",
                          }}
                          onClick={e =>
                            handleScenarioNext(scenario_index, question_index)
                          }
                        >
                          Next
                        </button>
                      </Box>
                    </Box>
                  </Box>
                ) : scenario_index === scenarioAllQuestions.length ? (
                  <>ALL DONE</>
                ) : (
                  <></>
                )
              )
            ) : scenario_index === pageHandler.scenario_index ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "40px" }}
              >
                <Box sx={{ ...DivCenter, width: "100%" }}>
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: Color.primary1,
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "32px",
                    }}
                  >
                    Scenario-{scenario_index + 1}
                  </Typography>
                </Box>
                <Box sx={{ ...DivCenter, width: "100%" }}>
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "36px",
                    }}
                  >
                    {scenario_item.scenario_que}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    ...DivCenter,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "33.33%" }} />
                  {/* End Test */}
                  <Box
                    sx={{
                      ...DivCenter,
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      width: "33.33%",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", color: "#969696" }}>
                      <span style={{ fontSize: "24px", color: "#561D5E" }}>
                        {formatTime(seconds)}
                      </span>{" "}
                      for{" "}
                      <span style={{ color: "#561D5E" }}>
                        {senarioQuestionsCount - 1}{" "}
                      </span>
                      more questions
                    </Typography>
                    <Typography
                      onClick={() => setEndModal(true)}
                      sx={{
                        color: "red",
                        fontWeight: "500",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      End Test
                    </Typography>
                  </Box>
                  {/* Next Button */}
                  <Box
                    sx={{
                      ...DivCenter,
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "33.33%",
                    }}
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                        padding: "12px 80px",
                        fontSize: "20px",
                        borderRadius: "20px",
                        color: "#fff",
                      }}
                      onClick={e => handleScenarioNext(scenario_index, null)}
                    >
                      Next
                    </button>
                  </Box>
                </Box>
              </Box>
            ) : scenario_index === scenarioAllQuestions.length ? (
              <>ALL DONE</>
            ) : (
              <></>
            )
          )}
      </>
    );
  }

  useEffect(() => {}, [scenarioAllQuestions]);
  const handleDontAskAgain = ({ target }) => {
    const { checked } = target;
    setShowSkipModal(!checked);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        padding: "5rem",
        userSelect: "none",
      }}
    >
      {fullScreen ? (
        <Box>
          {allQuestions.length > 0 ? (
            parseInt(testType.test_type_id) !== 5 ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "40px" }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Typography sx={{ fontSize: "16px" }}>
                    <span style={{ fontWeight: "600" }}>
                      {selectedQuestions + 1}
                    </span>{" "}
                    {allQuestions[selectedQuestions].que}
                  </Typography>
                  {allQuestions[selectedQuestions].allOptions.map(
                    (option, option_index) => (
                      <Box
                        sx={{
                          borderRadius: "20px",
                          padding: "16px",
                          border: "1px solid black",
                          backgroundColor:
                            allQuestions[selectedQuestions].res_index[0] ===
                            option.key
                              ? "rgba(245, 165, 54, 0.50)"
                              : "#fff",
                          boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
                          "&:hover": { cursor: "pointer" },
                        }}
                        onClick={e => {
                          if (
                            allQuestions[selectedQuestions].res_index[0] ===
                            option.key
                          ) {
                            handleClearResponse(e);
                          } else {
                            handleOptionsSelect(e, option, option_index);
                          }
                        }}
                      >
                        {allQuestions[selectedQuestions].res_index[0] ===
                        option.key ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{
                                width: "90%",
                              }}
                            >
                              <Typography>
                                {typeof option.options === "boolean"
                                  ? JSON.stringify(option.options)
                                  : option.options}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "10%",
                              }}
                              onClick={e => handleClearResponse(e)}
                            >
                              <Typography sx={{ fontSize: "14px" }}>
                                Clear Response
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            // onClick={e =>
                            //   handleOptionsSelect(e, option, option_index)
                            // }
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Typography>
                              {typeof option.options === "boolean"
                                ? JSON.stringify(option.options)
                                : option.options}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid #561D5E",
                      padding: "12px 80px",
                      fontSize: "20px",
                      borderRadius: "20px",
                    }}
                    onClick={() => handleSkipModal()}
                  >
                    Skip
                  </button>
                  <Box
                    sx={{ display: "flex", gap: "20px", alignItems: "center" }}
                  >
                    <Typography sx={{ fontSize: "16px", color: "#969696" }}>
                      <span style={{ fontSize: "24px", color: "#561D5E" }}>
                        {formatTime(seconds)}
                      </span>{" "}
                      for{" "}
                      <span style={{ color: "#561D5E" }}>
                        {allQuestions.length - selectedQuestions - 1}{" "}
                      </span>
                      more questions
                    </Typography>
                    <Typography
                      onClick={() => setEndModal(true)}
                      sx={{
                        color: "red",
                        fontWeight: "500",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      End Test
                    </Typography>
                  </Box>
                  <button
                    disabled={
                      allQuestions[selectedQuestions].res_index.length === 0
                        ? true
                        : false
                    }
                    style={{
                      background:
                        allQuestions[selectedQuestions].res_index.length === 0
                          ? "#E6E6E6"
                          : "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                      padding: "12px 80px",
                      fontSize: "20px",
                      borderRadius: "20px",
                      color: "#fff",
                    }}
                    onClick={e => handleNext()}
                  >
                    {selectedQuestions === allQuestions.length - 1
                      ? "Submit Test"
                      : "Next"}
                  </button>
                </Box>
              </Box>
            ) : (
              // This is only for SJT
              <ScenarioPage />
            )
          ) : (
            <>BEST OF LUCK</>
          )}
          <Webcam
            ref={webcamRef}
            videoConstraints={{ width: 150, height: 100, facingMode: "user" }}
            style={{ width: "100px", height: "100px" }}
          />
        </Box>
      ) : (
        <Box>
          Please Open full Screen
          <button onClick={() => openFullScreen()}>Allow Full Screen</button>
        </Box>
      )}

      <Modal
        open={endModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "20px",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "32px", fontWeight: "500" }}
          >
            Are you sure you would like to
            <br /> <span style={{ color: "red" }}>End Test</span>?
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "500",
              color: "#545454",
            }}
          >
            There are still questions which have remained unattempted. On ending
            the test, you will not be able to answer any of these questions
            <br /> Are you sure you would like to continue?
          </Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                width: "100%",
              }}
              onClick={() => setEndModal(false)}
            >
              Back to Test
            </button>
            <button
              style={{
                background:
                  "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                color: "#fff",
                width: "100%",
              }}
              onClick={() => {
                endTest();
                setEndModal(false);
              }}
            >
              Yes
            </button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={skipModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "20px",
            width: "50%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "32px", fontWeight: "500" }}
          >
            Are you sure you would like to <br />
            skip this question?
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "500",
              color: "#545454",
            }}
          >
            On skipping this question, you would not be able to revisit this
            question again and it will be considered that you have not given any
            answer for this particular question.
            <br /> Are you sure you would like to skip this question?
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={showSkipModal}
                      onChange={e => handleDontAskAgain(e)}
                    />
                  }
                  label="Don't ask me again"
                />
              </FormGroup>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <button
                style={{
                  border: "1px solid #561D5E",
                  padding: "12px 80px",
                  fontSize: "20px",
                  borderRadius: "20px",
                  width: "100%",
                }}
                onClick={() => {
                  setSkipModal(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  background:
                    "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                  padding: "12px 80px",
                  fontSize: "20px",
                  borderRadius: "20px",
                  color: "#fff",
                  width: "100%",
                }}
                onClick={() => {
                  if (testType.test_type_id === 5) {
                    handleScenarioNext(
                      pageHandler.scenario_index,
                      pageHandler.question_index,
                      "skipped"
                    );
                  } else {
                    handleNext();
                  }
                  setSkipModal(false);
                }}
              >
                Yes
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={cameraModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "20px",
            width: "30%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WarningAmberOutlinedIcon size={25} sx={{ color: Color.accent }} />
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "500",
                color: Color.accent,
              }}
            >
              Warning
            </Typography>
          </Box>
          <Typography
            sx={{ textAlign: "center", fontSize: "24px", fontWeight: "500" }}
          >
            {errorText}
          </Typography>
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                width: "fit-content",
              }}
              onClick={() => {
                setCameraModal(false);
                cameraModalRef.current = false;
              }}
            >
              I understand
            </button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={!fullScreen}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "20px",
            width: "30%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "24px", fontWeight: "500" }}
          >
            This test can only be attempted in full screen mode. Further
            attempts may lead to disqualification.
          </Typography>
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                width: "fit-content",
              }}
              onClick={() => openFullScreen()}
            >
              Go Full Screen
            </button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={submitModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        onClose={() => setSubmitModal(false)}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "40px",
            width: "35%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "24px", fontWeight: "500" }}
          >
            You are on the last question, Skipping this will submit the test
          </Typography>
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                width: "fit-content",
              }}
              onClick={() => setSubmitModal(false)}
            >
              Cancel
            </button>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                backgroundColor: "#561D5E",
                color: "#fff",
                fontSize: "20px",
                borderRadius: "20px",
                width: "fit-content",
              }}
              onClick={() => endTest()}
            >
              Submit
            </button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={tabSwitchingModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        onClose={() => setTabSwitchingModal(false)}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "20px",
            width: "30%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WarningAmberOutlinedIcon size={25} sx={{ color: Color.accent }} />
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "500",
                color: Color.accent,
              }}
            >
              Warning
            </Typography>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Switching tabs during the assessment is prohibited.
            <br /> Please stay focused and complete the current test.
          </Typography>
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                width: "fit-content",
              }}
              onClick={() => {
                setTabSwitchingModal(false);
                openFullScreen();
              }}
            >
              I understand
            </button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={testDesqualifyModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "20px",
            padding: "20px",
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "500",
                color: Color.red,
              }}
            >
              Test Terminated
            </Typography>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {testTerminationMessage}
          </Typography>
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "center" }}>
            <button
              style={{
                border: "1px solid #561D5E",
                padding: "12px 80px",
                fontSize: "20px",
                borderRadius: "20px",
                width: "fit-content",
              }}
              onClick={() => {
                setTestDesqualifyModal(false);
                endTest();
              }}
            >
              Close
            </button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Questions;
