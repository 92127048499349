import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
function Redirect() {
  const { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      const base64 = token
        .replace(/-/g, "+") // Replace '-' with '+'
        .replace(/_/g, "/") // Replace '_' with '/'
        .concat("="); // Add back any necessary padding '='

      // Decrypt the token SE8u)FbukixVG1t
      const decryptedBytes = CryptoJS.AES.decrypt(base64, "lwsxBgRN3v");
      const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      Cookies.set("token", decryptedToken);
      navigate("/StudentActivity");
    }
  }, []);

  return <div></div>;
}

export default Redirect;
