/* fonts */

import { Button } from "@mui/material";

// import { Dimensions } from "react-native";

// const dimensions = Dimensions.get('window')
export const FontFamily = {
  heading4: "Inter",
  sFProDisplaySemibold: "Inter",
  subheading2: "Inter",
};
/* font sizes */
export const FontSize = {
  overline_size: 10,
  bodySmallRoboto121604_size: 12,
  size_2xs: 12,
  size_xs: 13,
  size_sm: 14,
  subheading4_size: 14,
  bodyLargeRoboto162405_size: 16,
  size_xl: 18,
  size_2xl: 20,
  size_3xl: 22,
  headlineSmallRoboto24320_size: 24,
  heading4_size: 32,
  heading5: 20,
};
/* Colors */
export const Color = {
  white: "#fff",
  neutralMidGrey: "#8b8b8b",
  gray200: "#808080",
  blueBlack: "#172326",
  gray400: "rgba(255, 255, 255, 0.01)",
  gray500: "rgba(255, 255, 255, 0)",
  primary1: "#561D5E",
  neutralBlack: "#000",
  green: "#08AB3F",
  primary3: "#AB92BF",
  neutralLightGrey: "#e6e6e6",
  gainsboro200: "#d9d9d9",
  gainsboro300: "rgba(230, 230, 230, 0.01)",
  gainsboro400: "rgba(217, 217, 217, 0.1)",
  accent: "#f5a536",
  neutralDarkGrey: "#545454",
  darkSlateGray: "rgba(65, 65, 65, 0.6)",
  red: "#ff0000",  // Using hex value for red
  meetRed: "#EE4E4E",  // Consider renaming if this is not a standard term
  btnColor: "#AB92BF",
  feedbackColor: "rgba(255, 255, 255, 0.8)",
  offlineBackgroundColor:"#EDEDED",
  offlineAccentColor:"#D17001",
  offlineGreenColor:"#208D32",
  offlineRedColor:"#D32F2F"
};

/* Paddings */
export const Padding = {
  p_2xs: 6,
  p_xs: 8,
  p_sm: 10,
  p_md: 16,
  p_lg: 24,
  p_xl: 32,
};
/* Margins */
export const Margin = {
  m_7xs: -10,
  m_6xs: -2,
  m_5xs: 0,
  m_4xs: 2,
  m_3xs: 4,
  m_2xs: 6,
  m_xs: 8,
  m_sm: 10,
  // m_md: dimensions.width * .041,    //16
  // m_lg: dimensions.width * .0615,   //24
  m_xl: 32,
  m_2xl: 57,
  m_3xl: 79,
  m_4xl: 192,
  m_5xl: 221,
  m_6xl: 233,
  m_7xl: 257,
};
/* border radiuses */
export const Border = {
  br_3xs: 10,
  br_2xs: 11,
  br_xs: 15,
  br_sm: 17,
  br_md: 20,
  br_lg: 24,
  br_xl: 31,
  br_2xl: 32,
};


export const DivCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const PrimaryButton = ({ children, ...props }) => {
  return (
    <Button
      style={{
        background: Color.primary_gradient,
        color: "white",
        boxShadow: "none",
        textTransform: "none",
        ":&hover": {
          background: Color.primary_gradient,
          color: "white",
          boxShadow: "none"
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const PrimaryButtonOutlined = ({ children, ...props }) => {
  return (
    <Button
      style={{
        background: Color.white,
        color: Color.primary_gradient,
        boxShadow: "none",
        textTransform: "none",
        ":&hover": {
          background: Color.white,
          color: Color.primary_gradient,
          boxShadow: "none"
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const styles = {
  page: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  button_styles: {
    contained: {
      minWidth: '100px',
      width: 'auto',
      height: '40px',
      background: Color.primary1,
      color: Color.white,
      borderRadius: '12px',
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: Color.primary1,
        color: Color.white,
        boxShadow: 'none',
      },
      '&:disabled': {
        background: 'rgba(28, 142, 168, 0.4)',
        color: Color.white,
        boxShadow: 'none',
      }
    },
    outlined: {
      minWidth: '100px',
      width: 'auto',
      height: '44px',
      background: Color.white,
      color: Color.primary1,
      borderRadius: '12px',
      border: `1px solid ${Color.primary1}`,
      textTransform: 'none',
      '&:hover': {
        background: Color.white,
        color: Color.primary1,
      },
      '&:disabled': {
        background: 'rgba(28, 142, 168, 0.4)',
        color: Color.white,
      }
    }
  },
  filter_buttons: {
    minWidth: '100px',
    width: 'auto',
    background: Color.white,
    color: Color.primary1,
    borderRadius: '14px',
    border: `1px solid ${Color.primary1}`,
    textTransform: 'none',
    '&:hover': {
      background: 'rgba(195, 236, 245, 1)',
      color: Color.primary1,
    },
  },
}

export const modalBoxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  padding: "1.5rem 2rem",
  borderRadius: "20px",
  width: "auto",
  minWidth: "800px",
  height: "auto",
  minHeight: "250px"
}


export const feedbackbackground = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  position: "absolute",
  top: "10%",
  width: "max(71%, 991.2px)",
  height: "max(25%, 435px)",
  backdropFilter: "blur(11px)",
  borderBottomRightRadius: "6px",
  borderBottomLeftRadius: "6px",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
};

// Apply styles function
function applyStyles() {
  const maxWidth = Math.max(window.innerWidth * 0.7, 991.2);
  const maxHeight = Math.max(window.innerHeight * 0.25, 425);

  const width = `max(${maxWidth}px, 60%)`;
  const height = `max(${maxHeight}px, 435px)`;

  const styles = {
    ...feedbackbackground,
    width,
    height
  };

  if (window.innerWidth > 1500) {
    styles.width = "max(65.5%, 780px)";
    styles.left = "2.5%";
  }

  // Apply styles to your element here
  // For example:
  // const feedbackElement = document.getElementById('feedbackbackground');
  // Object.assign(feedbackElement.style, styles);
}

// Initial application of styles
applyStyles();

// Listen for window resize events and reapply styles
window.addEventListener("resize", applyStyles);

