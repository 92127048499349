import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Cookies from "js-cookie";
import Service from "../../services/httpService";

function Authorize() {
  const [loggedIn, setLoggedIn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const services = new Service();
  const clientId =
    "331878265619-n1b1ia44qjlp90amsdg2trohjqt9m51o.apps.googleusercontent.com";
  const handleClick = () => {
    // Send data to the parent window
    const data = {
      type: "AUTH",
      val: Cookies.get("token"),
    };
    console.log("MESAGE SEND TO EVERYONE", data);
    window.opener.postMessage(data, "*");
    window.close();
  };

  const handleSignup = () => {
    const data = {
      type: "SIGNUP",
    };
    window.opener.postMessage(data, "*");
    window.close();
  };

  const handleLogin = async () => {
    if (email !== "" && password !== "") {
      try {
        const res = await services.post("/login", {
          email: email,
          password: password,
        });

        if (res.status === 200) {
          Cookies.set("token", res.headers.jwt, {
            expires: 182.5,
          });
          Cookies.set("user_type", res.data.user_type, { expires: 182.5 });
          handleClick();
        }
      } catch (error) {
        setError(error.response.data.message);
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");

    if (token === undefined) {
      setLoggedIn(false);
    } else {
      handleClick();
    }
  }, []);
  return (
    <div
      style={{
        padding: "20px",
        width: "400px",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src="./Career_Carve_Logo__1_-removebg-preview 2.png" />
      <Typography>Sync your account with CareerCarve</Typography>
      {loggedIn ? (
        <Button
          sx={{
            background: "#561D5E",
            color: "#fff",
            borderRadius: "15px",
            "&:hover": { background: "#561D5E", color: "#fff" },
            width: "fit-content",
            textTransform: "none",
            padding: "5px 20px",
          }}
          onClick={() => handleClick()}
        >
          Authorize with careercarve
        </Button>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            onChange={e => {
              setEmail(e.target.value);
              setError("");
            }}
            value={email}
            name="email"
            id="outlined-basic"
            label="Email "
            variant="outlined"
            fullWidth
            InputProps={{ sx: { height: 50.4 } }}
            style={{
              marginTop: "20px",
              textAlign: "center",
              borderRadius: "5px",
            }}
          />

          <FormControl
            sx={{ width: "100%", mb: "10px" }}
            InputProps={{ sx: { height: 50.4 } }}
            variant="outlined"
            name="password"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              autoFocus
              // inputRef={input => input && input.focus()}

              onChange={e => {
                setPassword(e.target.value);
                setError("");
              }}
              value={password}
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(prev => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <div>
            <div
              className="signInButton"
              id="signInGoogleButton"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <GoogleOAuthProvider
                width={300}
                clientId={clientId}
                text="Login with Google"
              >
                <GoogleLogin
                  width={300}
                  text="Login with Google"
                  logo_alignment="center"
                  style={{ border: "1px solid #545454" }}
                  onSuccess={async credentialResponse => {
                    try {
                      const response = await services.post(`/googleAuth`, {
                        id_token: credentialResponse.credential,
                        signup_platform: "CareerCarve Web App",
                      });
                      if (response.status === 200) {
                        Cookies.set("token", response.headers.jwt, {
                          expires: 182.5,
                        });
                        Cookies.set("user_type", response.data.user_type, {
                          expires: 182.5,
                        });
                      }
                      handleClick();
                    } catch (err) {
                      console.log(err);
                      if (
                        err?.response.status === 400 &&
                        err?.response.data.message === "Unverified account"
                      ) {
                        setError("This email is Unverified.");
                      } else if (
                        err?.response.status === 400 &&
                        err?.response.data.message ===
                          "Alternate email already existing."
                      ) {
                        setError("Alternate email already existing.");
                      } else if (
                        err?.response.status === 400 &&
                        err?.response.data.message ===
                          "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
                      ) {
                        setError(
                          "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
                        );
                      } else if (
                        err?.response.status === 400 &&
                        err?.response.data.message ===
                          "Email already exists as alternate."
                      ) {
                        setError(
                          "This email already exists as an secondary email. Please login with your primary email."
                        );
                      } else if (
                        err?.response.status === 500 &&
                        err?.response.data.message ===
                          "Alternate email entry doesn't exists"
                      ) {
                        setError("Alternate email entry doesn't exists.");
                      }
                    }
                  }}
                  onError={() => {
                    window.alert("Login Failed");
                  }}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
          <Box>
            <Typography>Dont have an account ?</Typography>
            <Typography
              onClick={() => handleSignup()}
              sx={{
                color: "#561D5E",
                textAlign: "center",
                "&:hover": { cursor: "pointer" },
              }}
            >
              Signup
            </Typography>
          </Box>
          {error !== "" && <p style={{ color: "red" }}>{error}</p>}
          <Button
            sx={{
              background: "#561D5E",
              color: "#fff",
              borderRadius: "15px",
              "&:hover": { background: "#561D5E", color: "#fff" },
              width: "fit-content",
              textTransform: "none",
              padding: "5px 20px",
            }}
            onClick={() => handleLogin()}
          >
            Login
          </Button>
        </Box>
      )}
    </div>
  );
}

export default Authorize;
