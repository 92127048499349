import React from "react";
import { useLocation } from "react-router-dom";
import StandardLeftSideOfRD from "../StandardLeftSideOfRD/StandardLeftSideOfRD";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../Login/Login.css";
import "./DeleteAccount.css";
const ConfirmDeletionPage = () => {
  const location = useLocation();
  const { email_id } = location.state;

  const Mobile = useMediaQuery("(max-width:800px)");

  return (
    <div>
      {Mobile ? (
        <>
          <div
            className="mobileViewDeleteAccount"
            style={{ marginTop: "2rem" }}
          >
            <div className="mobileViewDeleteAccount">
              <div
                className="mobileViewDeleteAccount"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="mobileViewDeleteAccount"
                  src="logo_welcome.png"
                  alt="background"
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                />
                <img
                  className="mobileViewDeleteAccount"
                  src="CareerCarve.png"
                  alt="background"
                  style={{
                    height: "auto",
                    width: "200px",
                  }}
                />
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Inter",
                  }}
                  className="mobileViewDeleteAccount"
                >
                  {" "}
                  Placement | Preparedness | Perfected
                </p>
              </div>

              <div
                className="mobileViewDeleteAccount"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
              >
                <p
                  className="ptagsInDeleteAccount"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "2rem",
                    textAlign: "center",
                    marginTop: "2rem",
                  }}
                >
                  Your CareerCarve Account was successfully deleted
                </p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p className="ptagsInDeleteAccount">Account Deleted:</p>
                  <p
                    className="ptagsInDeleteAccount"
                    style={{ fontStyle: "italic", marginLeft: ".5rem" }}
                  >
                    {email_id}
                  </p>
                </div>
                <p
                  className="ptagsInDeleteAccount"
                  style={{ textAlign: "center" }}
                >
                  From this point, there are two ways to proceed:
                </p>
                <p
                  className="ptagsInDeleteAccount"
                  style={{ textAlign: "center" }}
                >
                  1. If you accidentally deleted your account, you have a 30-day
                  cancellation window to re-login and regain access to your
                  account
                </p>
                <p
                  className="ptagsInDeleteAccount"
                  style={{ textAlign: "center" }}
                >
                  2. After the 30-day window your account, respective purchases
                  and personal information will be permanently deleted
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="login-Container">
            <div className="login-Resumes_Image">
              <StandardLeftSideOfRD />
            </div>
            <div className="login-Main-content">
              <div className="login-l-form">
                <form
                  action=""
                  class="login-form"
                  style={{ marginTop: "20%", width: "600px" }}
                >
                  <div className="outlined-basic">
                    <p
                      className="ptagsInDeleteAccount"
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "2rem",
                      }}
                    >
                      Your CareerCarve Account was successfully deleted
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p className="ptagsInDeleteAccount">Account Deleted:</p>
                      <p
                        className="ptagsInDeleteAccount"
                        style={{ fontStyle: "italic", marginLeft: ".5rem" }}
                      >
                        {email_id}
                      </p>
                    </div>
                    <p className="ptagsInDeleteAccount">
                      From this point, there are two ways to proceed:
                    </p>
                    <p className="ptagsInDeleteAccount">
                      1. If you accidentally deleted your account, you have a
                      30-day cancellation window to re-login and regain access
                      to your account
                    </p>
                    <p className="ptagsInDeleteAccount">
                      2. After the 30-day window your account, respective
                      purchases and personal information will be permanently
                      deleted
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmDeletionPage;
