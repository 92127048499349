// import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Button, Modal as MUIModal, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useHMSActions, useHMSNotifications } from "@100mslive/react-sdk";
import { Color } from "../../../GlobalStyles";
import { AppContext } from "../../AppContext";
import {
  checkDate,
  formatConvertermonthfirst,
} from "../../services/dateFunctions";
import { convertToUTCMilliseconds } from "../../services/epochTime";
import {
  calculateEpochTimeInLocalTime,
  extractDateFromDateString,
} from "../../services/specialCharReplace";
import "../Mentors/Feedback/Feedback.css";
const ScheduleTimeAndPdf = props => {
  const location = useLocation();
  const notification = useHMSNotifications()?.data;
  const formInputs = useContext(AppContext);
  console.log("form inputs", formInputs);
  const hmsActions = useHMSActions();
  const { hmsSDKPeers } = hmsActions;
  //   const { path } = props;
  const { checkUser, slotsContextData } = props;
  console.log("slotsContextData", slotsContextData);
  const { isRoomJoinCalled } = hmsActions;
  console.log("checkUser", checkUser, slotsContextData);
  let slot = JSON.parse(window.sessionStorage.getItem("slot"));
  const [viewGuidelinesShow, setViewGuidelinesShow] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [timeRemainingStudent, setTimeRemainingStudent] = useState(0);
  // const [isActive, setIsActive] = useState(false);
  // const [isActiveStudent, setIsActiveStudent] = useState(false);
  console.log("hmsSDKPeers", hmsSDKPeers);
  console.log("SLOT", slot);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "20px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
    p: 4,
    minWidth: "600px",
  };

  useEffect(() => {
    const handleEffect = () => {
      console.log("pathname:", location.pathname);
      if (
        location.pathname !== "/FeedbackOverview" &&
        location.pathname !== "/FeedbackFromStudent"
      )
        setViewGuidelinesShow(true);
    };
    handleEffect();
  }, []);

  const startTimer = mentor_start_time => {
    console.log("mentor_start_time", mentor_start_time);
    let interval;
    const skuDuration = slot?.sku_duration;
    const durationInMinutes = parseInt(skuDuration?.match(/\d+/)[0]);
    const durationInMillis = durationInMinutes * 1000 * 60;
    const mentorJoinInMillis =
      slot?.mentor_join_time ||
      parseInt(window.sessionStorage.getItem("mentor_join_time")) ||
      mentor_start_time;
    console.log("mentorJoinInMillis", mentorJoinInMillis);
    const now = Date.now();
    const endTime = mentorJoinInMillis + durationInMillis;
    const initialTimeRemaining = endTime - now;
    console.log("initialTimeRemaining", initialTimeRemaining);
    setTimeRemaining(initialTimeRemaining);
    // setIsActive(true);

    interval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime > 0) {
          return prevTime - 1000;
        } else {
          clearInterval(interval);
          // setIsActive(false);
          return 0;
        }
      });
    }, 1000);
    console.log("interval", interval);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (isRoomJoinCalled) {
      if (checkUser === "mentor") {
        const peerId = Object.keys(hmsSDKPeers)[0]; // Get the peerId from the first key
        const peer = hmsSDKPeers[peerId]; // Get the peer object using the peerId
        console.log("peer details", peer);
        if (peer.role && peer.role.name === "mentor") {
          console.log(`Mentor found: ${peer.name}`);
          console.log("peer.joinedAt", peer.joinedAt);
          if (peer.joinedAt !== undefined) {
            console.log(`Joined at: ${peer.joinedAt}`);
            let mentorStartTime = convertToUTCMilliseconds(peer.joinedAt);
            console.log("mentorStartTime", mentorStartTime);
            startTimer(mentorStartTime);
          } else {
            const now = Date.now();
            startTimer(now);
          }
        }
      } else {
        startTimer(null);
      }
    }
  }, [isRoomJoinCalled, hmsSDKPeers]);

  useEffect(() => {
    if (checkUser === "student b2c" || checkUser === "student b2b") {
      const now = Date.now();
      const student_join_time =
        slot?.student_join_time ||
        parseInt(window.sessionStorage.getItem("student_join_time")) ||
        now; // Replace this with your actual value
      const mentorJoinInMillis = slot?.mentor_join_time
        ? slot?.mentor_join_time
        : parseInt(window.sessionStorage.getItem("mentor_join_time"));
      console.log("duration jbfe", mentorJoinInMillis);
      console.log("now", now);
      const endTime = student_join_time - mentorJoinInMillis;
      console.log("lost time", student_join_time, mentorJoinInMillis);
      // const initialTimeRemaining = endTime - now;
      setTimeRemainingStudent(endTime);
    }
  }, []);

  const formatTime = milliseconds => {
    console.log("what am i getting", milliseconds);
    if (milliseconds < 0) {
      return "00:00"; // or any default value you prefer when the time is negative
    }
    const minutes = Math.floor(milliseconds / 60000); // Convert milliseconds to minutes
    const seconds = Math.floor((milliseconds % 60000) / 1000); // Remaining seconds
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (notification?.description?.includes("room is not active")) {
      setViewGuidelinesShow(false);
    }
  }, [notification]);
  // const navigate = useNavigate();
  return (
    <>
      {checkUser !== "mentor" && isRoomJoinCalled && (
        <>
          <p
            style={{
              marginLeft: "2rem",
              color: "#fff",
              fontSize: "20px",
              justifyContent: "center",
              position: "absolute",
              alignItems: "center",
              marginLeft: "15%",
              marginTop: "1rem",
            }}
          >
            Time left in this session :{" "}
            <span style={{ color: Color.primary1, fontSize: "20px" }}>
              {formatTime(timeRemaining)}
            </span>
          </p>
        </>
      )}
      <div
        style={{
          float:
            checkUser === "student b2c" || checkUser === "student b2b"
              ? "right"
              : "",
          width:
            checkUser === "student b2c" || checkUser === "student b2b"
              ? "50%"
              : "",
          right:
            checkUser === "student b2c" || checkUser === "student b2b" ? 0 : "",
          position:
            checkUser === "student b2c" || checkUser === "student b2b"
              ? "absolute"
              : "",
        }}
      >
        <div>
          <Card
            sx={{
              minWidth: 200,
              margin: 2,
              marginBottom: 0,

              background: "#FFFFFF",
              border: "1px solid #E6E6E6",
              boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
              borderRadius:
                window.location.pathname === "/FeedbackOverview" ||
                window.location.pathname === "/FeedbackFromStudent"
                  ? "20px"
                  : "20px",
            }}
          >
            <CardContent>
              <div
                className="upcomingBookingContent"
                style={{
                  justifyContent:
                    window.location.pathname === "/FeedbackOverview" ||
                    window.location.pathname === "/FeedbackFromStudent"
                      ? "space-evenly"
                      : "space-between",
                  paddingLeft:
                    window.location.pathname === "/FeedbackOverview" ||
                    window.location.pathname === "/FeedbackFromStudent"
                      ? 0
                      : "2rem",
                  height:
                    window.location.pathname === "/FeedbackOverview" ||
                    window.location.pathname === "/FeedbackFromStudent"
                      ? "200px"
                      : "150px",
                }}
              >
                <div className="upcomingBookingDescriptionFeedback">
                  {window.location.pathname === "/FeedbackOverview" ||
                  window.location.pathname === "/FeedbackFromStudent" ? (
                    <>
                      <Typography variant="h5">{slot.act_type_name}</Typography>
                      <Typography sx={{}} color="color: #000000;">
                        with{" "}
                        {checkUser === "mentor"
                          ? slot?.user_name
                          : slot?.mentor_name}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="h6">{slot.act_type_name}</Typography>
                      <Typography sx={{}} color="color: #000000;">
                        {checkUser === "mentor"
                          ? slot?.user_name
                          : slot?.mentor_name}
                      </Typography>
                    </>
                  )}

                  <Typography
                    sx={{
                      fontSize:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? 18
                          : 16,
                      marginTop: "1%",
                    }}
                    color="#000000"
                    gutterBottom
                  >
                    {formatConvertermonthfirst(
                      extractDateFromDateString(slot.start_time)
                    )}
                    {checkDate(extractDateFromDateString(slot.start_time)) !==
                      "invalid" &&
                      "(" +
                        (checkDate(
                          extractDateFromDateString(slot.start_time)
                        ) === "today"
                          ? "Today"
                          : checkDate(
                              extractDateFromDateString(slot.start_time)
                            ) === "tomorrow"
                          ? "Tomorrow"
                          : checkDate(
                              extractDateFromDateString(slot.start_time)
                            ) === "yesterday"
                          ? "Yesterday"
                          : "") +
                        ")"}
                    , {calculateEpochTimeInLocalTime(slot.start_time)} -{" "}
                    {calculateEpochTimeInLocalTime(slot.end_time)}
                  </Typography>
                  <div
                    style={{
                      display:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? "inline"
                          : "inline",
                    }}
                  >
                    {window.location.pathname === "/FeedbackOverview" ||
                    window.location.pathname === "/FeedbackFromStudent" ? (
                      <Typography
                        // style={{ paddingLeft: "1rem" }}
                        variant="body2"
                        color="text.secondary"
                      >
                        {slot.duration} for {slot.role_name} <br />
                        in {slot.specialization_name}
                        <br />
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          style={{
                            fontSize: "16px",
                            marginTop: "6px",
                            width: "100%",
                            paddingLeft:
                              formInputs?.userType === "mentor"
                                ? "0rem"
                                : "1rem",
                          }}
                          variant="body2"
                          color="text.secondary"
                        >
                          {slot.role_name}
                        </Typography>
                      </>
                    )}

                    <div
                      style={{ paddingLeft: "1rem", width: "300px" }}
                      className="iconAndName1"
                    >
                      {console.log("slot.file_url", slot.file_url)}
                      {slot.file_url && (
                        <>
                          <PictureAsPdfIcon
                            style={{
                              color: "#561D5E",
                              marginRight: "5%",
                              marginTop: ".5rem",
                            }}
                          />
                          <button
                            style={{
                              marginTop:
                                window.location.pathname ===
                                  "/FeedbackOverview" ||
                                window.location.pathname ===
                                  "/FeedbackFromStudent"
                                  ? ".5rem"
                                  : 0,
                            }}
                            className="feedbackFileName"
                            onClick={() => window.open(slot.file_url)}
                          >
                            {slot.file_url
                              ?.substring(slot.file_url.lastIndexOf("/") + 1)
                              ?.substring(0, 10)
                              .concat(slot.file_url.length > 10 ? "..." : "")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    className="feedbackRelatedDocuments"
                    style={{ textAlign: "left", marginLeft: "1rem" }}
                  >
                    Related Documents
                  </p>

                  <div
                    className="upcomingBookingDocuments1"
                    style={{
                      overflowY: "auto",
                      justifyContent: "center",
                      border:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? "none"
                          : "1px solid #E6E6E6",
                      borderRadius:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? "0px"
                          : "20px",
                      height:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? ""
                          : "120px",
                      marginRight:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? ""
                          : "2rem",
                      paddingTop:
                        window.location.pathname === "/FeedbackOverview" ||
                        window.location.pathname === "/FeedbackFromStudent"
                          ? ""
                          : ".5rem",
                    }}
                  >
                    {/* {window.location.pathname === "/FeedbackOverview" ||
                window.location.pathname === "/FeedbackFromStudent" ? ( */}
                    {/* // ) : (
                //   <></>
                // )} */}
                    {slot.resume && (
                      <>
                        <div
                          className="iconAndName1"
                          style={{
                            paddingLeft:
                              window.location.pathname ===
                                "/FeedbackOverview" ||
                              window.location.pathname ===
                                "/FeedbackFromStudent"
                                ? ""
                                : "1rem",
                            paddingRight:
                              window.location.pathname ===
                                "/FeedbackOverview" ||
                              window.location.pathname ===
                                "/FeedbackFromStudent"
                                ? ""
                                : "1rem",
                          }}
                        >
                          <PictureAsPdfIcon
                            style={{ color: "#561D5E", marginRight: "5%" }}
                          />
                          <button
                            className="feedbackFileName"
                            onClick={() => window.open(slot.resume.url)}
                          >
                            {slot.resume.name
                              ?.substring(0, 15)
                              .concat(
                                slot.resume.name.length > 15 ? "..." : ""
                              )}
                          </button>
                        </div>
                      </>
                    )}
                    {console.log(
                      "slot.other_docs.files.length",
                      slot.other_docs.files.length
                    )}
                    {slot.other_docs.files.length > 0 &&
                      slot.other_docs.files?.map((file, index) => (
                        <div
                          key={index}
                          className="iconAndName1"
                          style={{
                            paddingLeft:
                              window.location.pathname ===
                                "/FeedbackOverview" ||
                              window.location.pathname ===
                                "/FeedbackFromStudent"
                                ? ""
                                : "1rem",
                            paddingRight:
                              window.location.pathname ===
                                "/FeedbackOverview" ||
                              window.location.pathname ===
                                "/FeedbackFromStudent"
                                ? ""
                                : "1rem",
                          }}
                        >
                          <PictureAsPdfIcon
                            style={{ color: "#561D5E", marginRight: "5%" }}
                          />
                          <button
                            // style={{ marginTop: ".5rem" }}
                            className="feedbackFileName"
                            onClick={() => window.open(file.url)}
                          >
                            {file.name}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: ".5rem",
            }}
          >
            {window.location.pathname === "/FeedbackOverview" ||
            window.location.pathname === "/FeedbackFromStudent" ? (
              <></>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {isRoomJoinCalled && (
                  <div>
                    {checkUser === "mentor" &&
                    slotsContextData?.mentor_join_time !== null ? (
                      <>
                        <p
                          style={{
                            marginLeft: "2rem",
                            color: "#fff",
                            fontSize: "16px",
                          }}
                        >
                          Time Remaining:{" "}
                          <span style={{ color: Color.primary1 }}>
                            {formatTime(timeRemaining)}
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          style={{
                            marginLeft: "2rem",
                            color: "#fff",
                            fontSize: "16px",
                            marginBottom: ".2rem",
                          }}
                        >
                          Lost Time:{" "}
                          <span
                            style={{
                              color: Color.red,
                              borderRadius: "30px",
                              paddingLeft: ".5rem",
                              paddingRight: ".5rem",
                              background:
                                "linear-gradient(0deg, rgba(255, 0, 0, 0.30) 0%, rgba(255, 0, 0, 0.30) 100%), #FFF",
                            }}
                          >
                            {formatTime(timeRemainingStudent)}
                          </span>
                        </p>
                      </>
                    )}

                    <p
                      style={{
                        marginLeft: "2rem",
                        color: "red",
                        fontSize: "16px",
                        marginBottom: checkUser === "mentor" ? "0px" : "1rem",
                      }}
                    >
                      Camera must be on during the session
                    </p>
                  </div>
                )}

                <button
                  style={{
                    marginRight: "2rem",
                    color: "#561D5E",
                    fontSize: "16px",
                    float: "right",
                    // height: "60px",
                  }}
                  onClick={() => setViewGuidelinesShow(true)}
                >
                  View Guidelines
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          <MUIModal
            open={viewGuidelinesShow}
            onClose={() => setViewGuidelinesShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between ",
                }}
              >
                <Typography id="modal-modal-title" component="h2" fontSize={40}>
                  Guidelines
                </Typography>
                <Button
                  variant="text"
                  class="close"
                  aria-label="Close"
                  onClick={() => setViewGuidelinesShow(false)}
                >
                  <CloseIcon />
                </Button>
              </div>
              <Typography
                id="modal-modal-description"
                color={Color.neutralMidGrey}
                sx={{ mt: 2 }}
              >
                Kindly follow the below guidelines while attending the session
              </Typography>
              <div>
                <Typography
                  id="modal-modal-description"
                  color={Color.primary1}
                  sx={{ mt: 2 }}
                >
                  Interview Environment
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "16px",
                    }}
                  >
                    <div style={{ width: "10%", marginRight: "40px" }}>
                      <img src="silence_icon_guidelines_diag.png" />
                    </div>
                    <div style={{ width: "10%" }}>
                      <CheckIcon sx={{ color: "#08AB3F" }} />
                    </div>
                    <div style={{ width: "80%", justifyContent: "left" }}>
                      <Typography id="modal-modal-description">
                        Quiet room without disturbance, blank background as much
                        as possible
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <div style={{ width: "10%", marginRight: "40px" }}>
                      <img src="video_icon_guidelines_diag.png" />
                    </div>
                    <div style={{ width: "10%" }}>
                      <CheckIcon sx={{ color: "#08AB3F" }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <Typography id="modal-modal-description">
                        Video must be on
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Typography
                  id="modal-modal-description"
                  color={Color.primary1}
                  sx={{ mt: 2 }}
                >
                  Dress Code
                </Typography>
                <div
                  style={{
                    display: "flex",
                    marginTop: "16px",
                    alignItems: "center",
                    marginBottom: "24px",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <img src="shirt_icon_guidelines_diag.png" />
                  </div>
                  <div
                    style={{
                      width: "90%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "90%",
                      }}
                    >
                      <div style={{ width: "10%" }}>
                        <CheckIcon sx={{ color: "#08AB3F" }} />
                      </div>
                      <div style={{ width: "90%" }}>
                        <Typography id="modal-modal-description">
                          Formals only(no need of blazer or tie)
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div style={{ width: "10%" }}>
                        <ClearIcon sx={{ color: "red" }} />
                      </div>
                      <div style={{ width: "90%" }}>
                        <Typography id="modal-modal-description">
                          Avoid tshirt even if it is collared
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Typography
                  id="modal-modal-description"
                  color={Color.primary1}
                  sx={{ mt: 2 }}
                >
                  Body Language
                </Typography>

                <div
                  style={{
                    display: "flex",
                    marginTop: "16px",
                    alignItems: "center",
                    marginBottom: "24px",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <img src="sofa_icon_guidelines_diag.png" />
                  </div>
                  <div style={{ width: "90%", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "10%" }}>
                        <CheckIcon sx={{ color: "#08AB3F" }} />
                      </div>
                      <div style={{ width: "90%" }}>
                        <Typography id="modal-modal-description">
                          Exactly similar to F2F interview
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "90%",
                        marginTop: "30px",
                      }}
                    >
                      <div style={{ width: "10%" }}>
                        <ClearIcon sx={{ color: "red" }} />
                      </div>
                      <div style={{ width: "90%" }}>
                        <Typography id="modal-modal-description">
                          Avoid doing interview on a bed or flat couches
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </MUIModal>
        </div>
      </div>
    </>
  );
};

export default ScheduleTimeAndPdf;
