import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
// import Cookies from "js-cookie";
// import FaLayerGroup from "@mui/icons-material/FaLayerGroup";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import PersonIcon from "@mui/icons-material/Person";
import ListItemButton from "@mui/material/ListItemButton";
import { AppContext } from "../../AppContext";
import "../SideNavBar/SidenavBar.css";
const SideNavBar = props => {
  const formInputs = useContext(AppContext);

  const navigate = useNavigate();
  const path = window.location.pathname;
  const [isHovered, setisHovered] = useState(false);

  return (
    <div>
      <div
        className="area"
        onMouseOver={() => setisHovered(true)}
        onMouseLeave={() => setisHovered(false)}
      >
        <nav className="main-menu">
          <ul>
            <li>
              <a href="/">
                <div
                  style={{
                    padding: "1.7rem",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    alt="logo"
                    src={isHovered ? "ExtendedRDLogo.png" : "RDLogo.png"}
                    style={{
                      // margin: "17px 6px 75px 12px",
                      // marginLeft: "6%",
                      width: "fit-content",
                      height: "30px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </a>
            </li>
          </ul>
          <ul>
            <li class="has-subnav">
              <button
                onClick={() =>
                  navigate(
                    `/MentorDashboard?user_id=${formInputs.formInputs.user_id}`,
                    {
                      state: { user_id: formInputs.formInputs.user_id },
                    }
                  )
                }
              >
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/MentorDashboard"
                      ? "sidenav-image-a-dashboard"
                      : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <HomeOutlinedIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Dashboard</span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li>

            <li class="has-subnav">
              <button onClick={() => navigate("/UpcomingBooking")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/UpcomingBooking"
                      ? "sidenav-image-a-dashboard"
                      : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <InsertInvitationOutlinedIcon
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Upcoming </span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li>

            <li class="has-subnav">
              <button onClick={() => navigate("/MentorPastBookings")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/MentorPastBookings"
                      ? "sidenav-image-a-dashboard"
                      : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <HistoryIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Past Bookings</span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li>

            <li class="has-subnav">
              <button onClick={() => navigate("/MyProfile")}>
                <div
                  style={{ display: "flex", width: "100%" }}
                  className={
                    path === "/MyProfile" ? "sidenav-image-a-dashboard" : ""
                  }
                >
                  <ListItemButton style={{ padding: "0px" }}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 10,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                    >
                      <PersonIcon style={{ width: "30px", height: "30px" }} />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Profile</span>
                    </div>
                  </ListItemButton>
                </div>
              </button>
            </li>
          </ul>

          {/* <ul className="logout" onClick={e => handleLogout(e)}>
            <li className="has-subnav">
              <a className="sidenav-image-a">
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{ padding: "18px 0px 18px 30px", width: "100px" }}
                  >
                    <i className="fa fa-power-off"></i>
                  </div>
                  <div style={{ padding: "14px 0px 9px 0px", width: "80%" }}>
                    <span className="nav-text">Logout</span>
                  </div>
                </div>
              </a>
            </li>
          </ul> */}
        </nav>
      </div>
    </div>
  );
};

export default SideNavBar;
