import * as React from "react";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Button, Container, TextField, Typography } from "@mui/material";
// import { Color } from "../../../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../ContactUs/ContactUs.css";
export default function ContactUs() {
  const Mobile = useMediaQuery("(min-width:800px)");

  const APKUrl =
    "https://play.google.com/store/apps/details?id=host.exp.exponent";
  const iOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";

  return (
    <div style={{ marginTop: "141px" }}>
      <Container
        className="landing-container"
        sx={{
          width: "80%",
          display: "flex",
          justifyContent: "center",
          flexDirection: Mobile ? "row" : "column", // center horizontally
        }}
      >
        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            fontSize="24px"
            align="center"
            sx={{
              position: "relative",
              "&::after": {
                content: "''",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "-10%",
                width: "64px",
                borderBottom: "2px solid #561D5E",
              },
              paddingBottom: "9px",
            }}
          >
            Download Now
          </Typography>
          <Container
            sx={{
              display: "flex",
              flexDirection: Mobile ? "row" : "column",
              justifyContent: "space-evenly",
              alignItems: "center", // add this property
              marginTop: "40px",
              textAlign: "center",
              gap: "33px",
            }}
          >
            <GooglePlayButton
              url={APKUrl}
              theme="light"
              className="download-buttons"
              width="100%"
              height="66px"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            />
            <AppStoreButton
              url={iOSUrl}
              theme="light"
              className="download-buttons"
              width="100%"
              height="66px"
            />
          </Container>
          <Typography variant="h5" align="center" sx={{ marginTop: "49px" }}>
            Connect with us
          </Typography>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "22px",
              gap: "22px",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              fullWidth
              sx={{
                borderRadius: "37px",
                gap: "12px",
                borderRadius: "20px",
                border: "1px solid #E6E6E6",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.125)",
              }}
            >
              <InstagramIcon /> Follow
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              fullWidth
              sx={{
                borderRadius: "37px",
                gap: "12px",
                borderRadius: "20px",
                // border: "none",
                border: "1px solid #E6E6E6",
                boxShadow: "0px 0px 15px rgba(0, 0, 0,0.125)",
              }}
            >
              <LinkedInIcon />
              Follow
            </Button>
          </Container>
        </Container>

        <Container
          sx={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            fontSize="24px"
            align="center"
            sx={{
              position: "relative",
              "&::after": {
                content: "''",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "-10%",
                width: "10%",
                borderBottom: "2px solid #561D5E",
              },
              paddingBottom: "9px",
            }}
          >
            Contact
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              width: "100%",
              marginTop: "26px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              placeholder="Enter your name"
              fullWidth
            />
            <TextField
              id="outlined-basic"
              label="Number"
              variant="outlined"
              placeholder="Enter your Phone Number"
              fullWidth
            />
          </div>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            placeholder="Enter your email id"
            fullWidth
            sx={{ marginTop: "16px" }}
          />
          <TextField
            id="outlined-basic"
            label="Comments"
            variant="outlined"
            placeholder="Enter your Comments"
            fullWidth
            multiline
            rows={4}
            sx={{ marginTop: "16px" }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: "16px",
              height: "44px",
              background:
                "linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%)",
              borderRadius: "11px",
              marginBottom: "66px",
            }}
          >
            Submit
          </Button>
        </Container>
      </Container>
    </div>
  );
}
