import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Box, Button, Menu, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { AppContext } from "../../AppContext";

function Navbar({ title, description }) {
  const formInputs = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = e => {
    console.log("Logout loader!!1");
    Cookies.remove("token");
    Cookies.remove("user_type");
    console.log("Logout loader!!2");
    navigate("/");
    console.log("Logout loader!!3");
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "32px" }}>{title}</Typography>
        <Typography sx={{ fontSize: "16px", color: "#8B8B8B" }}>
          {description}
        </Typography>
      </Box>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div style={{ right: "0px", textAlign: "right" }}>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <AccountCircleOutlinedIcon
              style={{ height: "50px", width: "50px", color: "black" }}
            />
          </Button>

          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            sx={{ borderRadius: "16px" }}
            onClose={handleClose}
            PaperProps={{
              elevation: 3,
              sx: {
                borderRadius: "16px",
              },
            }}
            MenuListProps={{
              sx: {
                width: "250px",
                paddingBottom: "2rem",
              },
              "aria-labelledby": "basic-button",
            }}
          >
            <p className="firstNameAndLastNAme">
              {formInputs.first_name} {formInputs.last_name}
            </p>
            <p className="firstNameAndLastNAme1">
              {formInputs.userType === "mentor" ? "Mentor" : "Student"}
            </p>
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <button className="logoutButton" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </Menu>
        </div>
      </div> */}
    </div>
  );
}

export default Navbar;
