import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Modal } from "@mui/material";
import { Color } from "../../../GlobalStyles";
// import Modal from "react-bootstrap/Modal";
import "./Loading.css";
export default function Loader(props) {
  const [secondsLeft, setSecondsLeft] = useState(10);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft => secondsLeft - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [secondsLeft]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <img
        alt="Loader..."
        src="/Loader/Logo Loader-1.gif"
        style={{ width: "150px", height: "auto" }}
      />
    </div>
  );
}

export function ActivityLoader({ show }) {
  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    height: 150,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(5px)",
  };
  return (
    <Modal
      open={show}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box sx={{ ...modalStyles }}>
        <CircularProgress size={50} sx={{ color: Color.primary1 }} />
      </Box>
    </Modal>
  );
}

export function SmallPageLoader({ show }) {
  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 250,
    height: 150,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(5px)",
  };
  return (
    <Modal
      open={show}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box sx={{ ...modalStyles }}>
        Loading...
        <br />
        <br />
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Box>
    </Modal>
  );
}
