import React, { Suspense, useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  // useLocation,
  useParams,
} from "react-router-dom";
// import { Download } from "@mui/icons-material";
import Cookies from "js-cookie";
import {
  HMSRoomProvider,
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Box, HMSThemeProvider } from "@100mslive/react-ui";
import { AppData } from "./components/AppData/AppData.jsx";
import { BeamSpeakerLabelsLogging } from "./components/AudioLevel/BeamSpeakerLabelsLogging";
// import { ErrorBoundary } from "./components/ErrorBoundary";
import FullPageProgress from "./components/FullPageProgress";
import { Header } from "./components/Header/Header.jsx";
import { Init } from "./components/init/Init";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
// import { Notifications } from "./components/Notifications";
import PostLeave from "./components/PostLeave";
import { ToastContainer } from "./components/Toast/ToastContainer";
import ApplyNow from "./screens/ApplyNow/ApplyNow.js";
import AssessmentTest from "./screens/AssessmentTest/AssessmentTest.jsx";
import EndTest from "./screens/AssessmentTest/EndTest.jsx";
import Instructions from "./screens/AssessmentTest/Instructions.jsx";
import Questions from "./screens/AssessmentTest/Questions.jsx";
import CartScreen from "./screens/CartScreens/CartScreen.js";
import ConfirmDeletionPage from "./screens/DeleteAccount/ConfirmDeletionPage.js";
import DeleteAccount from "./screens/DeleteAccount/DeleteAccount.js";
import DeleteAccountNotice from "./screens/DeleteAccount/DeleteAccountNotice.js";
import DeleteAccountOTP from "./screens/DeleteAccount/DeleteAccountOTP.js";
// import Jobs from "./screens/Recruiter/Jobs.js";
// import RecruiterDashboard from "./screens/Recruiter/RecruiterDashboard.jsx";
// import RecruiterJobs from "./screens/Recruiter/RecruiterJobs.jsx";
// import RecruiterLogin from "./screens/Recruiter/RecruiterLogin.jsx";
// import Schedule from "./screens/Recruiter/Schedule.jsx";
// import Shortlists from "./screens/Recruiter/Shortlists.jsx";
import DocumentsAndFeedback from "./screens/DocumentsAndFeedback/DocumentsAndFeedback.js";
import FileDownloader from "./screens/DownloadResource/DownloadResource.js";
import Examples from "./screens/Examples/Examples.js";
import ForgotPassword from "./screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./screens/ForgotPassword/ResetPassword";
import LandingPage from "./screens/LandingPage/LandingPage.js";
import Authorize from "./screens/Login/Authorize.js";
import Login from "./screens/Login/Login";
import LoginAsMentor from "./screens/Login/LoginAsMentor";
import Redirect from "./screens/Login/Redirect.js";
import MentorInterestForm from "./screens/MentorInterestForm/MentorInterestForm";
import EndSession from "./screens/Mentors/Feedback/EndSession.js";
// import Feedback from "./screens/Mentors/Feedback/Feedback";
import FeedbackOverview from "./screens/Mentors/FeedbackOverview/FeedbackOverview.js";
import FeedbackReview from "./screens/Mentors/FeedbackReview/FeedbackReview.js";
import MentorDashboard from "./screens/Mentors/MentorDashboard/MentorDashboard.js";
import OnGoingGroupSessions from "./screens/Mentors/MentorOnGoingSessions/OnGoingGroupSessions.js";
import MentorPastBookings from "./screens/Mentors/MentorPastBookings/MentorPastBookings.js";
import OfflineSession from "./screens/Mentors/OfflineSessions/OfflineSession.js";
import OSDocumentFeedback from "./screens/Mentors/OfflineSessions/OSDocumentFeedback.js";
import SessionSchedule from "./screens/Mentors/OfflineSessions/SessionSchedule.js";
import UpcomingBooking from "./screens/Mentors/UpcomingBooking/UpcomingBooking.js";
import MyProfileMain from "./screens/MyProfile/MyProfileMain.js";
import Pricing from "./screens/Pricing/Pricing.js";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import GetYourFreeAccount from "./screens/SignUp/GetYourFreeAccount";
import SignUp from "./screens/SignUp/SignUp";
import {
  PastBookingsSkeletonLoader,
  SkeletonLoader,
} from "./screens/SkeletonLoader/SkeletonLoader.js";
import FeedbackFromStudent from "./screens/Students/FeedbackFromStudent/FeedbackFromStudent";
import SessionNotStarted from "./screens/Students/SessionNotStarted/SessionNotStarted.js";
import StudentActivity from "./screens/Students/StudentActivity/StudentActivity.js";
import StudentDashboard from "./screens/Students/StudentDashboard/StudentDashboard.js";
import StudentGroupSession from "./screens/Students/StudentGroupSession/StudentGroupSession.js";
import StudentNotes from "./screens/Students/StudentNotes/StudentNotes.js";
import TermsOfUse from "./screens/TermsOfUse/TermsOfUse";
// import TestRedux from "./screens/TestRedux.js";
import VideoConferencing from "./screens/VideoConferencing/VideoConferencing.jsx";
import { Color } from "../GlobalStyles.js";
import CartOrderConfirmation from "../src/screens/CartOrderConfirmation/CartOrderConfirmation";
import { AppContext, ProvideFeedback, SlotsContext } from "./AppContext.js";
import { hmsActions, hmsStats, hmsStore } from "./hms.js";
import { UnderMaintenanceProvider } from "./UnderMaintenanceContext.js";
import { Confetti } from "./plugins/confetti";
import { RemoteStopScreenshare } from "./plugins/RemoteStopScreenshare";
import { getRoutePrefix, shadeColor } from "./common/utils";
import { FeatureFlags } from "./services/FeatureFlags";
import Service from "./services/httpService.js";
import {
  getBackendEndpoint,
  getUserToken as defaultGetUserToken,
} from "./services/tokenService";
import "./base.css";
import "./index.css";
const Conference = React.lazy(() => import("./components/conference"));
const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT;

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

let appName;
if (window.location.host.includes("localhost")) {
  appName = "localhost";
} else {
  appName = window.location.host.split(".")[0];
}

document.title =
  process.env.REACT_APP_TITLE || `${appName}'s ${document.title}`;

// TODO: remove now that there are options to change to portrait
const getAspectRatio = ({ width, height }) => {
  const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
  const portraitDomains = (
    process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || ""
  ).split(",");
  if (portraitDomains.includes(host) && width > height) {
    return { width: height, height: width };
  }
  return { width, height };
};

export function EdtechComponent({
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "1-1",
    font = "Inter",
    color = "#2AA8C4",
    theme = "light", // toggling dark theme and light theme
    logo = "",
    headerPresent = "false",
    metadata = "",
    recordingUrl = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
  getDetails = () => {},
  authTokenByRoomCodeEndpoint = "",
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map(el => parseInt(el));

  const getUserTokenCallback = useCallback(getUserToken, []); //eslint-disable-line
  return (
    // <ErrorBoundary>
    <HMSThemeProvider
      themeType={theme}
      aspectRatio={getAspectRatio({ width, height })}
      theme={{
        colors: {
          brandDefault: color,
          brandDark: shadeColor(color, -30),
          brandLight: shadeColor(color, 30),
          brandDisabled: shadeColor(color, 10),
        },
        fonts: {
          sans: [font, "Inter", "sans-serif"],
        },
      }}
    >
      <HMSRoomProvider
        isHMSStatsOn={FeatureFlags.enableStatsForNerds}
        actions={hmsActions}
        store={hmsStore}
        // notifications={hmsNotifications}
        stats={hmsStats}
      >
        <AppData
          appDetails={metadata}
          policyConfig={policyConfig}
          recordingUrl={recordingUrl}
          logo={logo}
          tokenEndpoint={tokenEndpoint}
        />

        <Init />
        <Box
          css={{
            bg: "$mainBg",
            w: "100%",
            ...(headerPresent === "true"
              ? { flex: "1 1 0", minHeight: 0 }
              : { h: "100%" }),
          }}
        >
          <AppRoutes
            getUserToken={getUserTokenCallback}
            getDetails={getDetails}
            authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
          />
        </Box>
      </HMSRoomProvider>
    </HMSThemeProvider>
    // </ErrorBoundary>
  );
}

const RedirectToPreview = ({ getDetails }) => {
  const { roomId, role } = useParams();
  console.log("room id and role", roomId, role, getDetails);
  const [slotsContextData, setSlotsContextData] = useState();
  const [provideFeedback, setProvideFeedback] = useState(false);
  console.log(
    "slotsContextData 1, provideFeedback",
    slotsContextData,
    provideFeedback
  );
  const [mark_absent, setMark_absent] = useState(false);
  useEffect(() => {
    getDetails();
  }, [roomId]); //eslint-disable-line
  // console.error({ roomId, role });
  console.log("0", roomId, role);
  if (!roomId && !role) {
    console.log("1", roomId, role);
    return <Navigate to="/" />;
  }
  if (!roomId) {
    console.log("2", roomId);
    return <Navigate to="/" />;
  }
  if (["streaming", "preview", "meeting", "leave"].includes(roomId) && !role) {
    console.log("3", roomId, role);
    return <Navigate to="/" />;
  }

  return (
    <AppContext.Provider
      value={{
        mark_absent,
        setMark_absent: data => setMark_absent(data),
        feedback_empty: true,
        setFeedback_empty: data => {},
      }}
    >
      <SlotsContext.Provider
        value={{
          slotsContextData,
          setSlotsContextData: data => setSlotsContextData(data),
        }}
      >
        <ProvideFeedback.Provider
          value={{
            provideFeedback,
            setProvideFeedback: data => setProvideFeedback(data),
          }}
        >
          <Navigate
            to={`${getRoutePrefix()}/preview/${roomId}/${role || ""}`}
          />
        </ProvideFeedback.Provider>
      </SlotsContext.Provider>
    </AppContext.Provider>
  );
};

export const RouteList = ({
  getUserToken,
  getDetails,
  authTokenByRoomCodeEndpoint,
  checkUser,
}) => {
  console.log("get details data", getDetails);
  return (
    <AppContext.Provider>
      <Routes>
        {" "}
        {/* THE TESTING SCREEN  */}
        <Route path="preview">
          <Route
            path=":roomId/:role"
            element={
              <Suspense fallback={<FullPageProgress />}>
                {console.log("PreviewScreen")}
                <PreviewScreen
                  getUserToken={getUserToken}
                  authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
                  checkUser={checkUser}
                />
              </Suspense>
            }
          />
          {/* THE ROUTE TO AUTHENTICATE USER AND AUTH TOKEN */}
          <Route
            path=":roomId"
            element={
              <Suspense fallback={<FullPageProgress />}>
                <PreviewScreen
                  getUserToken={getUserToken}
                  authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
                  checkUser={checkUser}
                />
              </Suspense>
            }
          />
        </Route>
        {/* THE MEETING ROOM */}
        <Route path="meeting">
          <Route
            path=":roomId/:role"
            element={
              <Suspense fallback={<FullPageProgress />}>
                <Conference checkUser={checkUser} />
              </Suspense>
            }
          />
          <Route
            path=":roomId"
            element={
              <Suspense fallback={<FullPageProgress />}>
                <Conference checkUser={checkUser} />
              </Suspense>
            }
          />
        </Route>
        {/* THE LEAVE ROOM (FEEDBACK ROOM) */}
        <Route path="leave">
          <Route path=":roomId/:role" element={<PostLeave />} />
          <Route path=":roomId" element={<PostLeave />} />
        </Route>
        <Route
          path="/:roomId/:role"
          element={<RedirectToPreview getDetails={getDetails} />}
        />
        <Route
          path="/:roomId/"
          element={<RedirectToPreview getDetails={getDetails} />}
        />
        {/* <Route path="*" element={<ErrorPage error="Invalid URL!" />} /> */}
      </Routes>
    </AppContext.Provider>
  );
};

const BackSwipe = () => {
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  useEffect(() => {
    const onRouteLeave = async () => {
      if (isConnectedToRoom) {
        await hmsActions.leave();
      }
    };
    window.addEventListener("popstate", onRouteLeave);
    return () => {
      window.removeEventListener("popstate", onRouteLeave);
    };
  }, [hmsActions, isConnectedToRoom]);
  return null;
};

function AppRoutes({ getUserToken, getDetails, authTokenByRoomCodeEndpoint }) {
  console.log("get userToken", getUserToken);
  const services = new Service();
  // const temp_data = ["streaming", "preview", "meeting", "leave"];
  // const location = useLocation();
  // console.log("location", location.pathname);
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    userType: "",
    user_id: null,
  });
  const [slotsContextData, setSlotsContextData] = useState();
  const [provideFeedback, setProvideFeedback] = useState(false);
  useEffect(() => {
    const handleEffect = async () => {
      console.log("cookies check in app.js:", Cookies.get("token"));
      if (Cookies.get("token") !== undefined) {
        // setCookiesAvailable(true);

        try {
          const preUser = await services.get("/user/userDetail");
          console.log("preUser", preUser);
          const temp_formInputs = { ...formInputs };
          temp_formInputs.first_name = preUser?.userDetail.first_name;
          temp_formInputs.user_id = preUser?.userDetail.user_id;
          if (
            ["undefined", "null", undefined, null].includes(
              temp_formInputs.first_name
            )
          ) {
            temp_formInputs.first_name = "";
          }

          temp_formInputs.last_name = preUser?.userDetail.last_name;
          if (
            ["undefined", "null", undefined, null].includes(
              temp_formInputs.last_name
            )
          ) {
            temp_formInputs.last_name = "";
          }
          temp_formInputs.userType = preUser?.user_type;
          console.log("handleEffect ~ temp_formInputs:", temp_formInputs);

          setFormInputs(temp_formInputs);
        } catch (err) {
          console.log(err);
        }
      }
    };
    handleEffect();
  }, []);

  return (
    <Router>
      <ToastContainer />
      {/* <Notifications /> */}
      <BackSwipe />
      <Confetti />
      <RemoteStopScreenshare />
      <KeyboardHandler />
      <BeamSpeakerLabelsLogging />
      <AppContext.Provider
        value={{
          formInputs,
          setFormInputs: data => setFormInputs(data),
        }}
      >
        <SlotsContext.Provider
          value={{
            slotsContextData,
            setSlotsContextData: data => setSlotsContextData(data),
          }}
        >
          <ProvideFeedback.Provider
            value={{
              provideFeedback,
              setProvideFeedback: data => setProvideFeedback(data),
            }}
          >
            <UnderMaintenanceProvider>
              <Routes>
                <Route path="/LandingPage" element={<LandingPage />} />
                <Route path="/Pricing" element={<Pricing />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/Authorize" element={<Authorize />} />
                <Route path="/TermsOfUse" element={<TermsOfUse />} />
                <Route path="/CartScreen" element={<CartScreen />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/ResetPassword">
                  <Route
                    path=":email/:token"
                    exact
                    element={<ResetPassword />}
                  />
                </Route>
                <Route path="/Assessment" element={<AssessmentTest />} />
                <Route path="/Instructions" element={<Instructions />} />
                <Route path="/Endtest" element={<EndTest />} />

                <Route path="/Questions">
                  <Route path=":test_id" element={<Questions />} />
                </Route>

                <Route
                  path="/CartOrderConfirmation"
                  element={<CartOrderConfirmation />}
                />
                <Route path="/SignUp" element={<SignUp />} />

                <Route path="/ApplyNow" element={<ApplyNow />} />
                <Route
                  path="/GetYourFreeAccount"
                  element={<GetYourFreeAccount />}
                />
                <Route path="/" element={<Login />} />
                <Route path="/Login" element={<Login />} />
                <Route
                  path="/MentorInterestForm"
                  element={<MentorInterestForm />}
                />
                <Route path="/LoginAsMentor" element={<LoginAsMentor />} />
                <Route
                  path="/UpcomingBooking/:user_type"
                  element={<UpcomingBooking />}
                />
                <Route path="/UpcomingBooking" element={<UpcomingBooking />} />
                <Route
                  path="/OnGoingGroupSessions"
                  element={
                    formInputs.userType === "mentor" ? (
                      <OnGoingGroupSessions />
                    ) : (
                      <StudentGroupSession />
                    )
                  }
                />
                <Route path="/SessionSchedule" element={<SessionSchedule />} />
                <Route path="/OfflineSession" element={<OfflineSession />} />
                <Route
                  path="/OSDocumentFeedback"
                  element={<OSDocumentFeedback />}
                />
                <Route
                  path="/MentorPastBookings"
                  element={<MentorPastBookings />}
                />
                <Route path="/SkeletonLoader" element={<SkeletonLoader />} />
                <Route
                  path="/PastBookingsSkeletonLoader"
                  element={<PastBookingsSkeletonLoader />}
                />
                <Route path="/DeleteAccount" element={<DeleteAccount />} />
                <Route
                  path="/FeedbackOverview"
                  element={<FeedbackOverview />}
                />
                <Route
                  path="/FeedbackOverview/offline"
                  element={<FeedbackOverview />}
                />
                <Route path="/Header" element={<Header />} />
                <Route path="/FeedbackReview" element={<FeedbackReview />} />
                <Route
                  path="/FeedbackReview/offline"
                  element={<FeedbackReview />}
                />
                <Route path="/EndSession" element={<EndSession />} />
                <Route
                  path="/FeedbackFromStudent"
                  element={<FeedbackFromStudent />}
                />
                <Route
                  path="/StudentActivity/:user_type"
                  element={<StudentActivity />}
                />
                <Route path="/StudentActivity" element={<StudentActivity />} />
                <Route path="/StudentNotes" element={<StudentNotes />} />
                <Route
                  path="/SessionNotStarted"
                  element={<SessionNotStarted />}
                />
                <Route path="/MentorDashboard" element={<MentorDashboard />} />
                <Route
                  path="/StudentDashboard"
                  element={<StudentDashboard />}
                />
                <Route path="/Profile" element={<MyProfileMain />} />
                <Route path="/Examples" element={<Examples />} />
                <Route
                  path="/DeleteAccountOTP"
                  element={<DeleteAccountOTP />}
                />
                <Route path="/Redirect/:token" element={<Redirect />} />
                <Route path="/Download" element={<FileDownloader />} />
                <Route
                  path="/DocumentsAndFeedback"
                  element={<DocumentsAndFeedback />}
                />
                <Route
                  path="/ConfirmDeletionPage"
                  element={<ConfirmDeletionPage />}
                />
                <Route
                  path="/StudentGroupSession"
                  element={<StudentGroupSession />}
                />
                <Route
                  path="/DeleteAccountNotice"
                  element={<DeleteAccountNotice />}
                />
                <Route
                  path="/*"
                  element={
                    <VideoConferencing
                      getUserToken={getUserToken}
                      getDetails={getDetails}
                      authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
                    />
                  }
                />
                <Route path="/JobDetails/:id" element={<ApplyNow />} />
              </Routes>
            </UnderMaintenanceProvider>
          </ProvideFeedback.Provider>
        </SlotsContext.Provider>
      </AppContext.Provider>
    </Router>
  );
}

export default function App() {
  // const location = useLocation();
  // console.log("location", location.pathname);
  return (
    <EdtechComponent
      themeConfig={{
        aspectRatio: "1-1",
        theme: "light",
        color: Color.primary3,
        logo: process.env.REACT_APP_LOGO,
        font: "Roboto",
        headerPresent: process.env.REACT_APP_HEADER_PRESENT,
        metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
      }}
      getUserToken={defaultGetUserToken}
    />
  );
}
