import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Cookies from "js-cookie";
import CCAppBar from "../../components/AppBar/AppBar";
import CollegeTieUps from "./CollegeTieUps/CollegeTieUps";
import ContactUs from "./ContactUs/ContactUs";
import ExpertConnect from "./ExpertConnect/ExpertConnect";
import Landing from "./Landing/Landing";
import MeetOurStars from "./MeetOurStars/MeetOurStars";
import MockInterview from "./MockInterview/MockInterview";
import OurPrograms from "./OurPrograms/OurPrograms";
import SipSuccess from "./SipSuccess/SipSuccess";
import WhyChooseUs1 from "./WhyChooseUs1/WhyChooseUs1";
import WhyChooseUs2 from "./WhyChooseUs2/WhyChooseUs2";
// import { Color } from "../../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../LandingPage/Landing/Landing.css";
export default function LandingPage() {
  const Mobile = useMediaQuery("(max-width:800px)");

  // const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ user_type: "" });

  useEffect(() => {
    const handleEffect = () => {
      const user_type = Cookies.get("user_type");
      setFormValues(user_type);
      console.log("fdsjlfjlksdf", formValues);
      // const token = Cookies.get("token");
      // if (
      //   token !== null &&
      //   token !== undefined &&
      //   token !== "null" &&
      //   token !== "undefined"
      // ) {
      //   console.log("token not null", token);
      //   if (
      //     user_type !== null &&
      //     user_type !== undefined &&
      //     user_type !== "null" &&
      //     user_type !== "undefined"
      //   ) {
      //     console.log("user_type not null", user_type);
      //     if (user_type === "mentor") {
      //       navigate("/UpcomingBooking", {
      //         state: { formValues: formValues, user_type: user_type },
      //       });
      //     } else if (user_type === "student b2c") {
      //       console.log("b2c");
      //       navigate("/StudentActivity", {
      //         state: { formValues: formValues, user_type: user_type },
      //       });
      //     } else {
      //       console.log("other");
      //       navigate("/StudentActivity", {
      //         state: { formValues: formValues, user_type: user_type },
      //       });
      //     }
      //   }
      // }
    };
    handleEffect();
  });

  const setToken = () => {
    const token = Cookies.get("token");
    Cookies.set("token", token, {
      domain: ".resumes.digital", // Parent domain for both websites
      path: "/", // Make it accessible throughout the sites
      secure: true, // Use HTTPS
      sameSite: "Lax", // Or 'None' for cross-origin requests
    });
  };
  return (
    <div
      className="MainLandingPage"
      style={{ width: Mobile ? "max(100%, 800px)" : "max(100%, 1200px)" }}
    >
      <CCAppBar />
      <Landing />

      <button onClick={setToken}>Set TOken</button>
      <div>
        <CollegeTieUps />
      </div>
      <div style={{ marginTop: "141px" }}>
        <MeetOurStars />
      </div>
      <div style={{ marginTop: "141px" }}>
        <OurPrograms />
      </div>
      <Typography
        fontSize="24px"
        align="center"
        fontWeight={400}
        sx={{
          position: "relative",
          "&::after": {
            content: "''",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "-10%",
            width: "64px",
            borderBottom: "2px solid #561D5E",
          },
          paddingBottom: "9px",
          marginTop: "141px",
        }}
      >
        Activities to Watch Out for
      </Typography>
      <MockInterview />
      <SipSuccess />
      <ExpertConnect />
      <WhyChooseUs1 />
      <WhyChooseUs2 />
      <ContactUs />
    </div>
  );
}
