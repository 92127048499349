import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie"; // Import Cookies if necessary
import { MuiOtpInput } from "mui-one-time-password-input";
import Alert from "../../components/Modal/Alert";
import { Color } from "../../../GlobalStyles";
import StandardLeftSideOfRD from "../StandardLeftSideOfRD/StandardLeftSideOfRD";
import useMediaQuery from "@mui/material/useMediaQuery";

const DeleteAccountOTP = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email_id } = location.state;
  const [otp, setOtp] = React.useState("");
  const handleChange = newValue => {
    setOtp(newValue);
  };
  const errRef = useRef();
  const [Confirmation_mgs, setConfirmation_mgs] = useState(""); //error mesage for the text input

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const Mobile = useMediaQuery("(max-width:800px)");
  console.log("Confirmation_mgs", Confirmation_mgs);
  const handleSubmitOTP = () => {
    const headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    axios
      .post(
        `https://backendstg.careercarve.in/api/delete/checkOTP`,
        {
          email_id: email_id,
          otp: parseInt(otp),
        },
        { headers }
      )
      .then(res => {
        console.log("Response from backend is", res?.content);
        console.log("Response from backend is", JSON.stringify(res, null, 2));
        res.data.success
          ? console.log("Success in Delete Account")
          : console.log("Failed in Delete Account, Email doesn't exist");
        setDeleteAccountModal(false);
        navigate("/ConfirmDeletionPage", { state: { email_id: email_id } });
      })
      .catch(err => {
        setConfirmation_mgs(err.response.data.message);
        console.log("Error Msg: ", err.response);
        console.log("failed To Send request");
      });
    setDeleteAccountModal(false);
  };
  const handleDeleteAccountModalOpen = () => {
    setDeleteAccountModal(true);
  };

  const handleCancelModal = () => {
    setDeleteAccountModal(false);
  };

  return (
    <div>
      {Mobile ? (
        <>
          <div
            className="mobileViewDeleteAccount"
            style={{ marginTop: "2rem" }}
          >
            <div className="mobileViewDeleteAccount">
              <div
                className="mobileViewDeleteAccount"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="mobileViewDeleteAccount"
                  src="logo_welcome.png"
                  alt="background"
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                />
                <img
                  className="mobileViewDeleteAccount"
                  src="CareerCarve.png"
                  alt="background"
                  style={{
                    height: "auto",
                    width: "200px",
                  }}
                />
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Inter",
                  }}
                  className="mobileViewDeleteAccount"
                >
                  {" "}
                  Placement | Preparedness | Perfected
                </p>
              </div>
              <div className="login-l-form">
                <form action="" class="login-form">
                  <div
                    className="outlined-basic"
                    style={{ top: "50%", bottom: "0" }}
                  >
                    <p
                      className="login-name"
                      style={{
                        fontSize: "18px",
                        marginBottom: "1rem",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      {`Enter OTP sent to ${email_id}`}
                    </p>

                    <MuiOtpInput
                      value={otp}
                      onChange={handleChange}
                      length={6}
                      width={Mobile ? "80%" : "100%"}
                      sx={{ margin: "0 auto" }}
                    />

                    <br />
                    <p style={{ marginBottom: "1rem", fontSize: "14px" }}>
                      <a
                        href="/DeleteAccount"
                        style={{
                          color: Color.primary1,
                          marginBottom: ".5rem",
                          fontSize: "14px",
                        }}
                      >
                        Entered incorrect email id?
                      </a>
                    </p>
                    <button
                      type="button"
                      className="delete-button"
                      style={{
                        textAlign: "center",
                        marginBottom: "1.5rem",
                        width: "80%",
                        margin: "0 auto",
                        justifyContent: "center",
                        fontFamily: "Inter",
                      }}
                      disabled={otp !== "" ? false : true}
                      onClick={handleDeleteAccountModalOpen}
                    >
                      Delete Account
                    </button>
                    <div>
                      <p
                        ref={errRef}
                        className="alert-errMsg-button"
                        aria-live="assertive"
                      >
                        {Confirmation_mgs}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <Alert
              show={deleteAccountModal}
              title="Caution!"
              body={
                <>
                  You are about to delete your CareerCarve account which will
                  permanently remove your data including personal information
                  and any purchases.
                  <br />
                  <br />
                  <b>Note:</b> A 30-day cancellation window will begin after
                  your confirmation within which if you re-login, you will
                  regain access to your account. <br />
                  <br />
                  After this 30-day window, you will permanently lose access to
                  this account.
                  <br />
                  <br />
                  Are you sure you want to delete your account?
                </>
              }
              alertResponse={handleSubmitOTP}
              onHide={() => setDeleteAccountModal(false)}
              Mobile={Mobile}
              cancelResponse={handleCancelModal}
            />
          </div>
        </>
      ) : (
        <>
          <div className="login-Container">
            <div className="login-Resumes_Image">
              <StandardLeftSideOfRD />
            </div>
            <div className="login-Main-content">
              <div className="fprgotPasswordCenter">
                <p className="login-name">Delete your account</p>
                <div className="login-l-form">
                  <form action="" class="login-form">
                    <div className="outlined-basic">
                      <p
                        // className="login-name"
                        style={{
                          fontSize: "18px",
                          marginBottom: "1rem",
                          fontFamily: "Inter",
                          fontWeight: 500,
                        }}
                      >
                        {`Enter OTP sent to ${email_id}`}
                      </p>
                      <MuiOtpInput
                        value={otp}
                        onChange={handleChange}
                        length={6}
                      />

                      <br />
                      <p style={{ marginBottom: "1rem", fontSize: "14px" }}>
                        <a
                          href="/DeleteAccount"
                          style={{
                            color: Color.primary1,
                            marginBottom: ".5rem",
                            fontSize: "14px",
                          }}
                        >
                          Entered incorrect email id?
                        </a>
                      </p>
                      {console.log("length", parseInt(otp).length)}
                      <button
                        type="button"
                        className="delete-button"
                        style={{
                          textAlign: "center",
                          marginBottom: "1.5rem",
                          width: "400px",
                        }}
                        disabled={otp !== "" ? false : true}
                        onClick={handleDeleteAccountModalOpen}
                      >
                        Delete Account
                      </button>
                      <div>
                        <p
                          ref={errRef}
                          className="alert-errMsg-button"
                          aria-live="assertive"
                        >
                          {Confirmation_mgs}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Alert
              show={deleteAccountModal}
              title="Caution!"
              body={
                <>
                  You are about to delete your CareerCarve account which will
                  permanently remove your data including personal information
                  and any purchases.
                  <br />
                  <br />
                  <b>Note:</b> A 30-day cancellation window will begin after
                  your confirmation within which if you re-login, you will
                  regain access to your account. <br />
                  <br />
                  After this 30-day window, you will permanently lose access to
                  this account.
                  <br />
                  <br />
                  Are you sure you want to delete your account?
                </>
              }
              alertResponse={handleSubmitOTP}
              onHide={() => setDeleteAccountModal(false)}
              cancelResponse={handleCancelModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DeleteAccountOTP;
