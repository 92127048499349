import React, { useContext, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Tabs } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import { Box, Modal } from "@mui/material";
// import Cookies from "js-cookie";
import {
  Color,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "../../../../GlobalStyles";
import { ProvideFeedback, SlotsContext } from "../../../AppContext";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import TimeService from "../../../services/epochTime";
import Service from "../../../services/httpService";
import "../UpcomingBooking/UpcomingBooking.css";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "center",
  backdropFilter: "blur(5px)",
};

function OfflineSession() {
  const [processes, setProcesses] = useState([]);
  const [recentProcesses, setRecentPrcoesses] = useState([]);
  const [process_id, setProcessId] = useState();
  const { setSlotsContextData } = useContext(SlotsContext);
  const { setProvideFeedback } = useContext(ProvideFeedback);
  const service = new Service();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageRecent, setCurrentPageRecent] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(false);
  const [isLoadingRecent, setIsLoadingRecent] = useState(false);
  const [settingType, setSettingType] = useState("upcoming");
  const time = new TimeService();

  const [sessionStudentData, setSessionStudentData] = useState([
    {
      order_id: 43,
      student_name: "",
      start_time: 1716215400000,
      end_time: 1716217200000,
      resume: [],
      other_docs: [],
      user_group_name: "",
      batch_type_name: "",
      college_name: "",
      act_type_id: "",
      act_type_name: "",
      sku_id: 0,
      sku_name: "",
    },
  ]);
  const [error, setError] = useState({
    open: false,
    onClose: () => setError({ ...error, open: false }),
    otp: "",
    heading: "",
  });
  const [showOTP, setShowOTP] = useState({
    open: false,
    onClose: () => setShowOTP({ ...showOTP, open: false }),
    otp: "",
    state: "take_otp", //or confirmation
  });
  // async function getProcessesOfAMentor() {
  //   try {
  //     const data = await service.get("/os/mentor/sessions");
  //     console.log("data:", data.data);
  //     setProcesses(data.data);
  //     return data.data;
  //   } catch (err) {
  //     console.log("err in getting processes of a mentor :", err);
  //   }
  // }

  // async function getRecentProcessesOfAMentor(type="recent", page=1 ) {
  //   const limit=4;
  //   try {
  //     const data = await service.get(`/os/mentor/sessions?type=${type}&limit=${limit}&page=${page}`);
  //     console.log("data in getRecentProcessesOfAMentor:", data.data);
  //     setProcesses(data.data);
  //     return data.data;
  //   } catch (err) {
  //     console.log("err in getRecentProcessesOfAMentor :", err);
  //   }
  // }

  // async function getUpcomingProcessesOfAMentor() {
  //   const limit=4;
  //   try {
  //     const data = await service.get(`/os/mentor/sessions?type=upcoming`);
  //     console.log("data in getUpcomingProcessesOfAMentor:", data.data);
  //     setProcesses(data.data);
  //     return data.data;
  //   } catch (err) {
  //     console.log("err in getting getUpcomingProcessesOfAMentor :", err);
  //   }
  // }
  console.log("set show :", showOTP);
  const getDataProcessesOfAMentor = async (type, page) => {
    if (page === 1 && type === "upcoming") {
      setIsLoadingUpcoming(true);
    } else if (page === 1 && type === "recent") {
      setIsLoadingRecent(true);
    }
    setSettingType(type);
    try {
      const res = await service.get(
        `/os/mentor/sessions?type=${type}&limit=${15}&page=${page}&status=${1}`
        // `scheduling/bookedSlot?user_type=${user_type}&limit=4&page=${page}&type=${type}`
      );
      console.log("response of mentor sessions:", res.data);
      let tempData = res.data.map(booking => ({
        ...booking,
        // features: JSON.parse(booking.features),
        // student_material: JSON.parse(booking.student_material),
        // resume: JSON.parse(booking.resume) || { url: "", name: "" },
        // other_docs: JSON.parse(booking.other_docs) || { files: [] },
      }));

      if (type === "upcoming") {
        if (currentPage === 1 || page !== currentPage) {
          //why
          setProcesses(prevData => {
            const updatedData = prevData.filter(existingItem => {
              const exists = tempData.some(
                newItem => newItem.id === existingItem.id
              );
              return exists ? false : true;
            });
            return [...updatedData, ...tempData];
          });
          setCurrentPage(page); // Update the currentPage for upcoming
          if (res.data.length === 0) {
            setIsLoadingUpcoming(false);
          }
        }
      } else if (type === "recent") {
        if (currentPageRecent === 1 || page !== currentPageRecent) {
          setRecentPrcoesses(prevData => {
            const updatedData = prevData.filter(existingItem => {
              const exists = tempData.some(
                newItem => newItem.id === existingItem.id
              );
              return exists ? false : true;
            });
            return [...updatedData, ...tempData];
          });
          setCurrentPageRecent(page); // Update the currentPageRecent for recent
          if (res.data.length === 0) {
            setIsLoadingRecent(false);
          }
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (type === "upcoming" && processes.length === 0) {
        setIsLoadingUpcoming(false);
      } else if (type === "recent" && recentProcesses.length === 0) {
        setIsLoadingRecent(false);
      }
    }
  };

  const putjoinSession = async received_otp => {
    const body = {
      // order_id: 43, // cannot send order_id
      otp: received_otp,
    };
    try {
      const res = await service.put("/os/mentor/joinSession", body);

      console.log("res in putjoinSession", res);
      window.sessionStorage.setItem(
        "slot",
        JSON.stringify({ ...res.data[0], id: res.data[0].order_id })
      );
      window.sessionStorage.setItem(
        "mentor_join_time",
        res.data[0].mentor_join_time
      );
      setSlotsContextData({
        ...res.data[0],
        id: res.data[0].order_id,
        sku_name: res.data[0].sku_name,
      });
      setSessionStudentData([...res.data]);
      setShowOTP({ ...showOTP, state: "confirmation" });
    } catch (err) {
      if (received_otp.length < 6) {
        setError({ ...error, open: true, heading: "OTP incomplete" });
      } else {
        setError({ ...error, open: true, heading: err.response.data.message });
      }
      console.log("err in otp:", err);
    }
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (windowHeight + scrollTop + 10 >= documentHeight && !isFetching) {
      setIsFetching(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching]);

  useEffect(() => {
    if (isFetching) {
      if (settingType === "upcoming") {
        getDataProcessesOfAMentor("upcoming", currentPage + 1);
      } else {
        getDataProcessesOfAMentor("recent", currentPageRecent + 1);
      }
      setIsFetching(false);
    }
  }, [isFetching]);

  const handleTabChange = (event, newValue) => {
    if (
      (newValue === 0 && settingType !== "upcoming") ||
      (newValue === 1 && settingType !== "recent")
    ) {
      if (newValue === 0) {
        setSettingType("upcoming");
      } else {
        setSettingType("recent");
        getDataProcessesOfAMentor("recent", currentPageRecent);
      }
    }
  };
  useEffect(() => {
    getDataProcessesOfAMentor("upcoming", 1);
    setProvideFeedback(false);
  }, []);

  console.log(
    "is upcoming and proceesses length:",
    isLoadingUpcoming,
    processes
  );
  return (
    <div
      style={{
        marginLeft: "100px",
      }}
    >
      <SideNavBar />
      <Modal
        open={showOTP.open}
        onClose={showOTP.onClose}
        // aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
      >
        <Box
          sx={{
            ...modalStyles,
            textAlign: "center",
            marginBottom: "12px",
          }}
        >
          {showOTP.state === "take_otp" ? (
            <Box>
              <div
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                Start Session{" "}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  color: "#F5A536",
                  marginBottom: "32px",
                }}
              >
                {" "}
                To start session, enter the unique 6 digit code provided by
                student. Note: Ask student to check the activity page on mobile
                app to find this unique code.
              </div>
              <div>
                <span>
                  Enter the unique Code
                  <span style={{ color: "red" }}>*</span>
                </span>
              </div>

              <OTPInput
                value={showOTP.otp}
                onChange={e => setShowOTP({ ...showOTP, otp: e })}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={props => <input {...props} />}
                inputStyle={{
                  borderWidth: "1px",
                  borderColor: Color.gainsboro200,
                  borderRadius: "8px",
                  // padding: "12px",
                  width: "48px",
                  height: "48px",
                  marginLeft: "10px",
                }}
                containerStyle={{
                  margin: "12px 0px",
                  justifyContent: "center",
                }}
              />
              <PrimaryButton
                variant="outlined"
                onClick={() => {
                  // setShowOTP({ ...showOTP, state: "confirmation" });
                  putjoinSession(showOTP.otp);
                }}
                // onClick={() => navigate("/FeedbackOverview")}
                // onClick={() => setShowOTP({ ...showOTP, open: true })}
                sx={{
                  textTransform: "none",
                  borderColor: Color.primary1,
                  background: Color.primary1,
                  color: Color.primary1,
                  borderRadius: "8px",
                  marginTop: "18px",
                  // marginLeft: "30px",
                  boxShadow: "none",
                  width: "100px",
                  "&:hover": {
                    background: Color.primary1,
                    borderColor: Color.primary1,
                    color: Color.primary1,
                  },
                }}
              >
                Submit
              </PrimaryButton>
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                }}
              >
                Ready to start?
              </div>
              <div
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                }}
              >
                Please check if you are talking to{" "}
                {sessionStudentData[0].student_name}.{" "}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                }}
              >
                Specialization : {sessionStudentData[0].sku_name}
                {/* {studentData?.spe_name} */}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  marginBottom: "32px",
                }}
              >
                Slot :{" "}
                {time.convertEpochToDate(sessionStudentData[0].start_time)} ,
                {time.convertEpochToTime(sessionStudentData[0].start_time)} -{" "}
                {time.convertEpochToTime(sessionStudentData[0].end_time)}
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "50px" }}
              >
                <PrimaryButton
                  variant="outlined"
                  // onClick={() => navigate("/FeedbackOverview")}
                  onClick={() =>
                    setShowOTP({
                      ...showOTP,
                      open: false,
                      state: "take_otp",
                    })
                  }
                  style={{
                    color: Color.primary1,
                  }}
                  sx={{
                    textTransform: "none",
                    borderColor: Color.primary1,
                    background: Color.white,
                    color: Color.primary1,
                    borderRadius: "8px",
                    marginTop: "32px",
                    marginLeft: "30px",
                    boxShadow: "none",
                    width: "250px",
                  }}
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  variant="outlined"
                  onClick={() =>
                    navigate("/FeedbackOverview/offline", {
                      state: {
                        studentData: sessionStudentData[0],
                        process_id: process_id,
                      },
                    })
                  }
                  // onClick={() =>
                  //   setShowOTP({ ...showOTP, open: false, state: "take_otp" })
                  // }
                  sx={{
                    textTransform: "none",
                    borderColor: Color.primary1,
                    background: Color.primary1,
                    color: Color.primary1,
                    borderRadius: "8px",
                    marginTop: "32px",
                    marginLeft: "30px",
                    boxShadow: "none",
                    width: "250px",
                    "&:hover": {
                      background: Color.primary1,
                      borderColor: Color.primary1,
                      color: Color.primary1,
                    },
                  }}
                >
                  Start Session
                </PrimaryButton>
              </div>
            </Box>
          )}
        </Box>
      </Modal>

      <Modal
        onClose={() => setError({ ...error, open: false })}
        open={error.open}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={{ ...modalStyles, width: "300px", height: "200px" }}>
          <div
            style={{
              fontSize: "20px",
              color: "#F5A536",
              marginBottom: "32px",
            }}
          >
            Warning
          </div>
          <div>{error.heading}</div>
        </Box>
      </Modal>
      <div>
        <div
          style={{
            marginLeft: "50px",
            padding: "12px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
            //   justifyContent: "space-around",
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ArrowBackIcon
              onClick={() => navigate(-1)}
              style={{
                color: Color.primary1,
                fontSize: "32px",
                marginRight: "10px",
              }}
              color={Color.primary1}
            />
            <div style={{ fontSize: "32px" }}>Offline Activities</div>{" "}
          </div>
          {/* <Button
            onClick={() => setShowOTP(prev => ({ ...prev, open: true }))}
            className="Rephrasebutton"
            style={{
              color: Color.white,
              width: "300px",
            }}
          >
            View guidelines
          </Button> */}
        </div>
        <Tabs
          aria-label="tabs"
          value={settingType === "upcoming" ? 0 : 1} // Set value based on settingType
          sx={{
            marginLeft: "100px",
            bgcolor: "transparent",
            width: "85%",
            alignSelf: "center",
            marginBottom: "1.5rem",
          }}
          onChange={handleTabChange}
        >
          <TabList
            disableUnderline
            sx={{
              p: 0.5,
              gap: 0.5,
              borderRadius: "xl",
              bgcolor: "background.level1",
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: "sm",
                bgcolor: Color.primary1,
                color: Color.white,
              },
            }}
          >
            <Tab sx={{ width: "50%" }} disableIndicator>
              Upcoming Bookings
            </Tab>
            <Tab sx={{ width: "50%" }} disableIndicator>
              Recent Bookings
            </Tab>
          </TabList>
          <TabPanel value={0}>
            {isLoadingUpcoming && (
              <>
                <div
                  style={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <SkeletonLoader />
                </div>
                <div
                  style={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <SkeletonLoader />
                </div>
                <div
                  style={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <SkeletonLoader />
                </div>
              </>
            )}
            {isLoadingUpcoming ? null : processes.length === 0 ? (
              <div className="noUpcomingBookings">
                <div className="noUpcomingBookingsIcon">
                  <EventBusyIcon
                    sx={{
                      color: "#561D5E",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </div>
                <div className="noUpcomingBookingsTexts">
                  <h1 className="noUpcomingBookingsText">
                    No Upcoming Bookings
                  </h1>
                  <p
                    className="fontFamilyFixed"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Book an activity through the app <br />
                    to view your bookings here
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  flexWrap: "wrap",
                  // marginRight: "100px",
                }}
              >
                {processes.map(slot => (
                  <div>
                    <OfflineContainer
                      setShowOTP={setShowOTP}
                      settingType={settingType}
                      item={slot}
                      setProcessId={setProcessId}
                    />
                  </div>
                ))}
              </div>
            )}
          </TabPanel>
          <TabPanel value={1}>
            {isLoadingRecent && (
              <>
                <div
                  style={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <SkeletonLoader />
                </div>
                <div
                  style={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <SkeletonLoader />
                </div>
                <div
                  style={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <SkeletonLoader />
                </div>
              </>
            )}
            {isLoadingRecent ? null : recentProcesses?.length === 0 ? (
              <div className="noUpcomingBookings">
                <div className="noUpcomingBookingsIcon">
                  <EventBusyIcon
                    sx={{
                      color: "#561D5E",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </div>
                <div className="noUpcomingBookingsTexts">
                  <h1 className="noUpcomingBookingsText">No Recent Bookings</h1>
                  <p
                    className="fontFamilyFixed"
                    style={{ textAlign: "center" }}
                  >
                    Book an activity through the app <br />
                    to view your bookings here
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  flexWrap: "wrap",
                  // marginRight: "100px",
                  // marginLeft: "100px",
                }}
              >
                {recentProcesses.map(slot => (
                  <div>
                    <OfflineContainer settingType={settingType} item={slot} />
                  </div>
                ))}
              </div>
            )}
          </TabPanel>
        </Tabs>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            flexWrap: "wrap",
            // marginRight: "100px",
            marginLeft: "100px",
          }}
        >
          {processes.map((item, index) => {
            return <OfflineContainer item={item} />;
          })}
        </div> */}
      </div>
    </div>
  );
}

export default OfflineSession;

export const OfflineContainer = props => {
  const navigate = useNavigate();
  console.log("item:", props);
  const sku_name = JSON.parse(props.item.sku).sku_name;
  console.log("sku name:", sku_name);
  return (
    <div
      style={{
        width: "370px",
        height: props?.settingType === "recent" ? "200px" : "200px",
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(359deg, #F2F2F2 -5.76%, #FFFFFF 114.16%)",
        justifyContent: "space-between",
        borderColor: Color.primary1,
        borderRadius: "20px",
        borderWidth: "1px",
        padding: "24px 32px",
        color: Color.primary1,
      }}
    >
      <div>
        <div>{props?.item.college_name}</div>
        <div>
          {sku_name} • {props.item.user_group_name} •{" "}
          {props.item.batch_type_name}
        </div>
      </div>
      <div>
        <PrimaryButtonOutlined
          variant="outlined"
          onClick={() =>
            navigate(`/SessionSchedule?process_id=${props?.item.id}`, {
              state: {
                data: props?.item,
              },
            })
          }
          sx={{
            textTransform: "none",
            borderColor: Color.primary1,
            color: Color.primary1,
            borderRadius: 0,
            boxShadow: "none",
            width: "100%",
            borderRadius: "8px",
            "&:hover": {
              borderColor: Color.primary3,
              color: Color.primary3,
            },
          }}
        >
          {props?.settingType === "upcoming"
            ? "View schedule"
            : "View Previous Activities"}
        </PrimaryButtonOutlined>
        {props?.settingType === "upcoming" && (
          <PrimaryButton
            variant="outlined"
            onClick={() => {
              props.setProcessId(props.item.id);
              props.setShowOTP(prev => ({ ...prev, open: true }));
            }}
            sx={{
              textTransform: "none",
              borderColor: Color.primary1,
              background: Color.primary1,
              color: Color.primary1,
              borderRadius: "8px",
              marginTop: "12px",
              boxShadow: "none",
              width: "100%",
              "&:hover": {
                background: Color.primary1,
                borderColor: Color.primary1,
                color: Color.primary1,
              },
            }}
          >
            Start Session
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};
