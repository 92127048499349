import React from "react";
// firebase apple login
// import * as firebase from "firebase/app";
import StandardLeftSideOfRD from "../StandardLeftSideOfRD/StandardLeftSideOfRD";
// import { useHistory } from "react-router-dom";
// import Service from "../../services/httpService";
// import UpcomingBooking from "../../screens/Mentors/UpcomingBooking/UpcomingBooking";
// import Validations from "../../screens/Validations/Validations";
import "react-toastify/dist/ReactToastify.css";
import "../Login/Login.css";
const ApplyNow = () => {
  // const slot = JSON.parse(window.sessionStorage.getItem("slot"));

  // console.log("pathname now", pathName);

  return (
    <>
      <div className="login-Container">
        <div className="login-Resumes_Image">
          <StandardLeftSideOfRD />
        </div>

        <div className="login-Main-content">
          <p className="login-name">Complete</p>
          <p className="signUpProcessText">
            this step on CareerCarve mobile app
          </p>
          <p className="signUpDownloadText">Download Now</p>
          {/* <div className="signUpBorderLine"></div> */}
          <div className="googleAndAppleStoreButton">
            <button className="googlePlayBUtton">
              <a href="https://play.google.com/store/apps/details?id=com.careercarve">
                <div>
                  <div className="signUpFlexButton">
                    <img
                      className="imageFlexible"
                      src="google-play.svg"
                      alt="background"
                      style={{
                        backgroundRepeat: "none",
                        height: "40px",
                        width: "40px",
                        marginRight: ".5rem",
                      }}
                    />
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "14px",
                          color: "#545454",
                        }}
                      >
                        Get it on
                      </p>
                      <p className="AppStoreText">Google Play</p>
                    </div>
                  </div>
                </div>
              </a>
            </button>
            <button className="appStoreBUtton">
              <a href="https://apps.apple.com/in/app/careercarve/id6447052699">
                <div
                  className="signUpFlexButton"
                  style={{ paddingLeft: ".6rem" }}
                >
                  <img
                    className="imageFlexible"
                    src="App_Store_(iOS).svg"
                    alt="background"
                    style={{
                      backgroundRepeat: "none",
                      height: "40px",
                      width: "40px",
                      marginRight: ".5rem",
                    }}
                  />
                  <div style={{ paddingLeft: ".2rem" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        color: "#545454",
                      }}
                    >
                      Download from
                    </p>
                    <p className="AppStoreText">App Store</p>
                  </div>
                </div>
              </a>
            </button>
            <div
              style={{
                width: "200px",
                height: "200px",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              <img src="QR_code_sign_up.jpg" />
            </div>
          </div>
          {/* <div style={{ textAlign: "center", width: "100%" }}>
            <button
              className="LoginNowBUtton"
              onClick={() => navigate("/")}
              style={{ marginTop: "2rem" }}
            >
              Login Now
            </button>
          </div> */}
          <br />
        </div>
      </div>
    </>
  );
};

export default ApplyNow;
