import React, { useEffect, useState } from "react";
import { useSearchParam } from "react-use";
// import Webcam from "react-webcam";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useAVToggle, useHMSActions } from "@100mslive/react-sdk";
import PreviewContainer from "../Preview/PreviewContainer";
import { useTokenEndpoint } from "./../AppData/useUISettings";
import getToken from "../../services/tokenService";
import { QUERY_PARAM_PREVIEW_AS_ROLE } from "../../common/constants";
import "./testyourDevice.css";
// import { AudioVideoToggle } from "../AudioVideoToggle";
// import { PreviewTile } from "../Preview/PreviewJoin";
const env = process.env.REACT_APP_ENV;

function TestYourDevice({ setShowModal }) {
  let step = [
    {
      label: "1",
      isCompleted: false,
      content: (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>
              Step-1
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              Browser will ask permission for Mic and Camera.
              <span style={{ color: "#561D5E" }}>
                {" "}
                Grant permission to both
              </span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Have you given the permission?
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      label: "2",
      isCompleted: false,
      content: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>
              Step-2
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              Check if your <span style={{ color: "#561D5E" }}>CAMERA </span> is
              working fine
            </Typography>
          </Box>
          <img
            src="./Testyourdevice/TYD_camera.png"
            alt="camera"
            className="testyourdevice_camera"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Is your camera working fine?
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      label: "3",
      isCompleted: false,
      content: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>
              Step-3
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              Check if your <span style={{ color: "#561D5E" }}>MIC </span> is
              working fine. Speak something and the outline of the square box
              should glow <span style={{ color: "#561D5E" }}>blue </span>
            </Typography>
          </Box>
          <img
            src="./Testyourdevice/TYD_MIC.png"
            alt="camera"
            className="testyourdevice_mic"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Is your mic working fine?
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      label: "4",
      isCompleted: false,

      content: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>
              Step-3
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              Check if your <span style={{ color: "#561D5E" }}>SPEAKER </span>{" "}
              is working fine. Click on the settings icon and then select "test"
              in the bottom right corner. If you hear a sound, it is working
              fine.
            </Typography>
          </Box>
          <img
            src="./Testyourdevice/TYD_speaker.png"
            alt="camera"
            className="testyourdevice_speaker"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Is your speaker working fine?
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      label: "5",
      isCompleted: false,

      content: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <Typography sx={{ fontSize: "16px", color: "#545454" }}>
              Step-5
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              You’re all set to begin the session now! Go to the{" "}
              <span style={{ color: "#561D5E" }}> Activity Page </span> to get
              started.
            </Typography>
          </Box>
          <img
            src="./Testyourdevice/TYD_joinRoom.png"
            alt="camera"
            className="testyourdevice_joinRoom"
          />
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Is your mic working fine?
            </Typography>
          </Box> */}
        </Box>
      ),
    },
  ];
  const [steps, setSteps] = useState(step);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeContent, setActiveContent] = useState(steps[activeIndex]);
  // const [videoPermission, setVideoPermission] = useState(false);
  // const [audioPermission, setAudioPermission] = useState(false);
  const previewAsRole = useSearchParam(QUERY_PARAM_PREVIEW_AS_ROLE);
  const { toggleVideo, isLocalVideoEnabled } = useAVToggle();
  const [token, setToken] = useState(null);
  const roomCode = "64be1c7a98586b7b14d1ac8c";
  const hmsActions = useHMSActions();
  const tokenEndpoint = useTokenEndpoint();

  const handleNext = e => {
    const temp_index = activeIndex;
    const temp_step = steps.map((item, index) => {
      if (index === temp_index) {
        return {
          ...item,
          isCompleted: true,
        };
      } else {
        return item;
      }
    });
    setSteps(temp_step);
    console.log("Steps", temp_step);

    console.log("TEmpIndex", temp_index);
    setActiveIndex(temp_index + 1);
    setActiveContent(temp_step[temp_index + 1]);
  };
  const handleBack = e => {
    const temp_index = activeIndex;
    const temp_step = steps.map((item, index) => {
      if (index === temp_index - 1) {
        return {
          ...item,
          isCompleted: false,
        };
      } else {
        return item;
      }
    });
    setSteps(temp_step);
    console.log("Steps", temp_step);

    console.log("TEmpIndex", temp_index);
    setActiveIndex(temp_index - 1);
    setActiveContent(temp_step[temp_index - 1]);
  };

  useEffect(() => {
    const getTokenFn = () =>
      getToken(tokenEndpoint, uuid(), "mentor", roomCode);
    getTokenFn()
      .then(token => {
        console.log("Token", token);
        setToken(token);
      })
      .catch(error => {
        console.log("Error Occured");
      });
    if (!isLocalVideoEnabled) {
      setTimeout(toggleVideo, 1000);
    }
  }, []);

  useEffect(() => {
    const handleEndConcert = () => {
      hmsActions.leave();
    };

    window.addEventListener("unload", handleEndConcert);
    return () => {
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, []);

  const handleToggleVideo = () => {
    if (isLocalVideoEnabled) {
      toggleVideo();
    }
    setShowModal(false);
  };

  // useEffect(() => {
  //   if (!isLocalVideoEnabled) {
  //     toggleVideo();
  //   }
  // }, []);
  return (
    <Box
      sx={{
        position: "relative",
        bgcolor: "#fff",
        borderRadius: "1rem",
        width: "60vw",
        top: "3rem",
        left: "20rem",
        height: "85vh",
        // paddingBottom: "2.5rem",
        // paddingRight: "1rem",
        // paddingTop: "1rem",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            bgcolor: "black",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
            bgColor: "black",
            justifyContent: "center",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <PreviewContainer
            initialName="Test"
            skipPreview={false}
            env={env}
            onJoin={null}
            token={token}
            asRole={previewAsRole}
            type="test"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
            width: "100%",
            paddingTop: "0.5rem",
            paddingBottom: "3rem",
            paddingRight: "2rem",
          }}
        >
          <IconButton
            sx={{ marginLeft: "90%" }}
            onClick={() => handleToggleVideo()}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              // width: "100%",
              justifyContent: "center",
              display: "flex",
              gap: "0.5rem",
            }}
          >
            {steps.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  // width: "100%",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid #561D5E ",
                    bgcolor: item.isCompleted
                      ? "rgba(28, 142, 168, 0.20)"
                      : "#fff",
                    padding: "0.2rem 0.6rem",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>
                    {item.label}
                  </Typography>
                </Box>
                {index !== 4 && (
                  <Box
                    className="testyourpage_lable_line"
                    sx={{
                      bgcolor: item.isCompleted
                        ? "rgba(28, 142, 168, 0.20)"
                        : "#545454",
                    }}
                  >
                    {" "}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          <Box sx={{}}>{activeContent.content}</Box>
          <Box>
            {activeIndex === 0 ? (
              <button
                // sx={{
                //   bgcolor: "#561D5E",
                //   width: "10rem",
                //   color: "#fff",
                //   borderRadius: "0.5rem",
                //   padding: "1rem , 3rem",
                //   "&:hover": {
                //     bgcolor: "#561D5E",
                //   },
                // }}
                onClick={e => {
                  handleNext(e);
                }}
                className="tyd_submitbutton"
              >
                Yes, Next Step
              </button>
            ) : activeIndex === 4 ? (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  // sx={{
                  //   bgcolor: "#fff",
                  //   width: "10rem",
                  //   color: "#561D5E",
                  //   borderRadius: "0.5rem",
                  //   border: "1px solid #561D5E",
                  //   padding: "1rem , 3rem",
                  //   "&:hover": {
                  //     bgcolor: "#fff",
                  //   },
                  // }}
                  className="tyd_previous_button"
                  onClick={() => handleBack()}
                >
                  Previous Step
                </button>
                <button
                  // sx={{
                  //   bgcolor: "#561D5E",
                  //   width: "10rem",
                  //   color: "#fff",
                  //   borderRadius: "0.5rem",
                  //   padding: "1rem , 3rem",
                  //   "&:hover": {
                  //     bgcolor: "#561D5E",
                  //   },
                  // }}
                  className="tyd_submitbutton"
                  onClick={() => handleToggleVideo()}
                >
                  Close
                </button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  // sx={{
                  //   bgcolor: "#fff",

                  //   width: "10rem",
                  //   color: "#561D5E",
                  //   borderRadius: "0.5rem",
                  //   border: "1px solid #561D5E",
                  //   padding: "1rem , 3rem",
                  //   "&:hover": {
                  //     bgcolor: "#fff",
                  //   },
                  // }}
                  className="tyd_previous_button"
                  onClick={() => handleBack()}
                >
                  Previous Step
                </button>
                <button
                  // sx={{
                  //   bgcolor: "#561D5E",
                  //   width: "10rem",
                  //   color: "#fff",
                  //   borderRadius: "0.5rem",
                  //   padding: "1rem , 3rem",
                  //   "&:hover": {
                  //     bgcolor: "#561D5E",
                  //   },
                  // }}
                  onClick={e => {
                    handleNext(e);
                  }}
                  className="tyd_submitbutton"
                >
                  Yes, Next Step
                </button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TestYourDevice;
