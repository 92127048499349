import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Cookies from "js-cookie";
// import { Header } from "../../../components/Header";
import { ActivityLoader } from "../../../components/Loader/Loader";
import ScheduleTimeAndPdf from "../../ScheduleComponents/ScheduleTimeAndPdf";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Service from "../../../services/httpService";
import { send_specialCharReplace } from "../../../services/specialCharReplace";
import "../../Mentors/FeedbackOverview/FeedbackOverview.css";
import "../../ScheduleComponents/Schedule.css";
import "../FeedbackFromStudent/FeedbackFromStudent.css";
const FeedbackFromStudent = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const slot = JSON.parse(window.sessionStorage.getItem("slot"));
  const services = new Service();
  const toggleButtonRef = useRef([]);
  const [conditionals, setConditionals] = useState({
    badModal: false,
    bad_comment: false,
  });
  const [badModalErrorMessage, setBadModalErrorMessage] = useState("");
  const [numRows, setNumRows] = useState({ bad: null, good: null });
  //main data
  const [feedbackData, setFeedbackData] = useState({
    form_data: {
      bad: [
        {
          options: [""],
          question: "",
          select_type: "",
          conditional_err: [{ option: "", err_message: "" }],
        },
      ],
      good: [
        {
          options: [""],
          question: "",
          select_type: "",
          conditional_err: [{ option: "", err_message: "" }],
        },
      ],
      main_question: "",
    },
  });

  const handleClearOption = () => {
    let temp_studentFeedback = { ...feedbackData };
    temp_studentFeedback.form_data.rating = 0;
    temp_studentFeedback.form_data.bad[0].selected[0] = "";
    setFeedbackData(temp_studentFeedback);
  };

  //validation
  const [formErrors, setFormErrors] = useState({ bad_comment: "" });
  //bad modal
  function ChangeTemplateModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="ModalBody">
          <p
            className="modalBodyText"
            style={{ color: "#000", fontSize: "24px" }}
          >
            Error
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center", // Optional: This centers items horizontally within the flex container
              width: "100%",
            }}
          >
            <p className="modalBodyText">{badModalErrorMessage}</p>
            <Button
              type="button"
              variant="contained"
              // className="StartSEssionbutton"
              onClick={() => {
                handleClearOption();
                setConditionals({ ...conditionals, badModal: false });
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                margin: "auto", // This centers the button horizontally
                color: "white",
                background: "#2AA8C4",
                textTransform: "none",
                "&:hover": {
                  background: "#2AA8C4", // Set the same color as the normal background color
                },
              }}
            >
              Go back
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //fetch data
  const fetchStudentFeedback = async () => {
    try {
      const res = await services.get(
        `studentModule/studentFeedback?act_type_id=${slot.act_type_id}`
      );
      console.log(res.data);
      if (res.data.length === 0)
        window.alert(
          "Please somebody enter data in the Order Table. Please for the love of GAWD. PLEASE. (PS: There is no data in Database so this won't work properly). Contact Ashish Anand."
        );
      return res.data[0];
    } catch (err) {
      window.alert(err);
    }
  };
  useEffect(() => {
    const handleEffect = async () => {
      let temp_studentFeedback = await fetchStudentFeedback();
      // let temp_studentFeedback = { ...feedbackData };
      console.log("handleEffect ~ temp_studentFeedback:", temp_studentFeedback);
      temp_studentFeedback.form_data = JSON.parse(
        temp_studentFeedback.form_data
      );
      temp_studentFeedback.form_data.rating = 0;
      temp_studentFeedback.form_data.bad.forEach(obj => (obj.comment = ""));
      temp_studentFeedback.form_data.good.forEach(obj => (obj.comment = ""));
      temp_studentFeedback.form_data.bad.forEach(obj => (obj.selected = []));
      temp_studentFeedback.form_data.good.forEach(obj => (obj.selected = []));
      setFeedbackData(temp_studentFeedback);
      setLoading(false);
    };
    handleEffect();
  }, []);

  useEffect(() => {
    let temp_studentFeedback = { ...feedbackData };
    if (temp_studentFeedback.form_data.rating <= 3) {
      temp_studentFeedback.form_data.good.forEach(obj => {
        obj.selected = [];
        obj.comment = "";
      });
    } else {
      temp_studentFeedback.form_data.bad.forEach(obj => {
        obj.selected = [];
        obj.comment = "";
      });
    }
    // return the updated feedbackData
    setFeedbackData(temp_studentFeedback);
  }, [feedbackData.form_data.rating]);

  //toggle button for Good are added to the main data
  const handleClick = (index_1, index_2, option) => {
    // buttonRefs.current[index].style.backgroundColor = newColor;
    toggleButtonRef.current[index_2].style.backgroundColor =
      toggleButtonRef.current[index_2].style.backgroundColor === "transparent"
        ? "rgba(56, 193, 223, 0.2)"
        : "transparent";

    let temp_studentFeedback = { ...feedbackData };
    temp_studentFeedback.form_data.good[index_1].selected.push(option);
    console.log("handleClick ~ temp_studentFeedback:", temp_studentFeedback);
    setFeedbackData(temp_studentFeedback);
  };

  const handleChange = (e, index_1, index_2) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormErrors({ ...formErrors, bad_comment: "" });
    let temp_studentFeedback = { ...feedbackData };
    if (name === "simple-controlled")
      temp_studentFeedback.form_data.rating = value;
    else if (name === "bad_radio_button") {
      // temp_studentFeedback.form_data.bad[index_1].options[index_2] = value;
      temp_studentFeedback.form_data.bad[index_1].selected[0] = value;
      let check_value = temp_studentFeedback.form_data.bad[
        index_1
      ].options.filter(
        obj =>
          !temp_studentFeedback.form_data.bad[index_1].conditional_err.some(
            cond_err => cond_err.option === obj
          )
      );
      console.log({ check_value });
      if (check_value.includes(value)) {
        console.log(
          "inside if of bad comment true",
          check_value.includes(value),
          value
        );
        setConditionals({ ...conditionals, bad_comment: true });
      }
      for (var option in temp_studentFeedback.form_data.bad[index_1]
        .conditional_err) {
        if (
          value ===
          temp_studentFeedback.form_data.bad[index_1].conditional_err[option]
            .option
        ) {
          setConditionals({
            ...conditionals,
            badModal: true,
            bad_comment: false,
          });
          setBadModalErrorMessage(
            temp_studentFeedback.form_data.bad[index_1].conditional_err[option]
              .err_message
          );
        }
      }
    } else if (name == "bad_comment") {
      temp_studentFeedback.form_data.bad[index_1].comment = value;
      /*showing all data in textArea*/
      const textareaLineHeight = 34; // Adjust this value to match your CSS line-height
      const previousRows = e.target.rows;
      e.target.rows = 1; // Reset the number of rows to 1 to calculate the new height
      const currentRows = Math.ceil(e.target.scrollHeight / textareaLineHeight);
      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }
      setNumRows({ ...numRows, bad: currentRows });
      /*showing all data in textArea*/
    } else if (name == "good_comment") {
      temp_studentFeedback.form_data.good[index_1].comment = value;
      /*showing all data in textArea*/
      const textareaLineHeight = 34; // Adjust this value to match your CSS line-height
      const previousRows = e.target.rows;
      e.target.rows = 1; // Reset the number of rows to 1 to calculate the new height
      const currentRows = Math.ceil(e.target.scrollHeight / textareaLineHeight);
      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }
      setNumRows({ ...numRows, good: currentRows });
      /*showing all data in textArea*/
    }
    console.log("handleChange ~ temp_studentFeedback:", temp_studentFeedback);
    setFeedbackData(temp_studentFeedback);
  };

  const handleSubmit = async () => {
    let body = JSON.parse(JSON.stringify(feedbackData));

    body = {
      order_id: slot.id,
      student_feedback: {
        rating: feedbackData.form_data.rating,
        good: feedbackData.form_data.good.map(good => ({
          question: good.question,
          selected: good.selected,
          comment: send_specialCharReplace(good.comment),
        })),
        bad: feedbackData.form_data.bad.map(bad => ({
          question: bad.question,
          selected: bad.selected,
          comment: send_specialCharReplace(bad.comment),
        })),
      },
    };

    if (body.student_feedback.rating <= 3) {
      console.log("bad comment:", body.student_feedback.bad);
      console.log(
        "bad comment length:",
        body.student_feedback.bad[0].comment.length
      );
      if (body.student_feedback.bad[0].comment.length < 50) {
        setFormErrors({
          ...formErrors,
          bad_comment: "Please enter atleast 50 characters",
        });
      } else {
        body = {
          order_id: slot.id,
          student_feedback: {
            rating: feedbackData.form_data.rating,
            bad: feedbackData.form_data.bad.map(bad => ({
              question: bad.question,
              selected: bad.selected,
              comment: send_specialCharReplace(bad.comment),
            })),
          },
        };

        try {
          const res = await services.post(
            `studentModule/studentFeedbackResponse`,
            body
          );
          console.log(res);
          if (res.status === 200)
            navigate(`/StudentActivity`, {
              state: {
                user_type: Cookies.get("user_type"),
              },
            });
        } catch (err) {
          window.alert(err.response.data.message);
        }
      }
    } else if (body.student_feedback.rating > 3) {
      if (body.student_feedback.good[0].comment.length === 0) {
        body = {
          order_id: slot.id,
          student_feedback: {
            rating: feedbackData.form_data.rating,
            good: feedbackData.form_data.good.map(good => ({
              question: good.question,
              selected: good.selected,
            })),
          },
        };
      } else
        body = {
          order_id: slot.id,
          student_feedback: {
            rating: feedbackData.form_data.rating,
            good: feedbackData.form_data.good.map(good => ({
              question: good.question,
              selected: good.selected,
              comment: send_specialCharReplace(good.comment),
            })),
          },
        };
      console.log("cookie", Cookies.get("user_type"));
      try {
        const res = await services.post(
          `studentModule/studentFeedbackResponse`,
          body
        );
        console.log(res);
        if (res.status === 200)
          navigate(`/StudentActivity`, {
            state: {
              user_type: Cookies.get("user_type"),
            },
          });
      } catch (err) {
        window.alert(err.response.data.message);
      }
    }
  };

  return loading ? (
    <ActivityLoader show={loading} />
  ) : (
    <div className="feedbackOverviewMainContainer">
      <div className="feedbackOverviewScheduleView">
        {/* <Header /> */}
        <ScheduleTimeAndPdf />
      </div>
      <div
        className="feedbackOverviewScheduleView"
        style={{ paddingLeft: "1rem" }}
      >
        <p className="feedbackFromStudentptag">Feedback</p>
        <p className="feedbackFromStudentText">
          How was your {slot.act_type_name} Session?
        </p>
        <Box
          className="feedbackRating1"
          sx={{
            "& > legend": { mt: 2 },
          }}
        >
          <Rating
            sx={{ color: "#561D5E" }}
            className="feedbackRating"
            name="simple-controlled"
            value={feedbackData.form_data.rating}
            onChange={e => {
              handleChange(e);
            }}
            icon={<StarIcon sx={{ color: "#561D5E" }} />}
            emptyIcon={<StarBorderIcon sx={{ color: "#561D5E" }} />}
          />
        </Box>
        {feedbackData.form_data.rating <= 3 &&
        feedbackData.form_data.rating > 0 ? (
          feedbackData.form_data.bad.map((bad, bad_index) => (
            <>
              <div>
                <p className="feedbackFromStudentText">{bad.question}</p>
                {bad.options.map((option, option_index) => (
                  <div style={{ marginBottom: "1rem" }}>
                    <input
                      name="bad_radio_button"
                      className="modalBodyText"
                      style={{
                        margin: "auto",
                        height: "20px",
                        width: "20px",
                        marginRight: "1rem",
                      }}
                      type="radio"
                      value={option}
                      onChange={e => handleChange(e, bad_index, option_index)}
                    />
                    {option}
                    <br />
                  </div>
                ))}
                {conditionals.bad_comment === true ? (
                  <div>
                    <p className="feedbackFromStudentText">
                      Comments<span style={{ color: "red" }}>*</span>
                    </p>
                    <textarea
                      name="bad_comment"
                      placeholder="Type in a comment..."
                      className="EditFeedbackOverviewProvided"
                      value={feedbackData.form_data.bad[bad_index].comment}
                      style={{
                        width: "100%",
                        height: "100%",
                        marginBottom: "0px",
                        marginLeft: "2px",
                      }}
                      rows={numRows.bad}
                      onChange={e => handleChange(e, bad_index)}
                    />
                    <p style={{ color: "red", marginBottom: "1.5rem" }}>
                      {formErrors.bad_comment}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ))
        ) : feedbackData.form_data.rating > 3 ? (
          <>
            {feedbackData.form_data.good.map((good, good_index) => (
              <>
                <div>
                  <p className="feedbackFromStudentText">
                    {good.main_question}
                  </p>
                  <div
                    className="optionsButtons"
                    style={{ marginBottom: "1rem" }}
                  >
                    {good.options.map((option, option_index) => (
                      <button
                        // ref={toggleButtonRef}
                        ref={el => (toggleButtonRef.current[option_index] = el)}
                        className="Optionsbutton"
                        onClick={() =>
                          handleClick(good_index, option_index, option)
                        }
                        style={{ backgroundColor: "transparent" }}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                  <div>
                    <p className="feedbackFromStudentText">
                      Comments (optional)
                    </p>
                    <textarea
                      name="good_comment"
                      className="EditFeedbackOverviewProvided"
                      placeholder="Type in a comment if you like..."
                      value={feedbackData.form_data.good[good_index].comment}
                      style={{ width: "100%", height: "100%" }}
                      rows={numRows.good}
                      onChange={e => handleChange(e, good_index)}
                    />
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          <></>
        )}
        {feedbackData.form_data.rating > 0 && (
          <button
            style={{
              float: "left",
              textAlign: "center",
              marginTop: "0",
              height: "40px",
              marginBottom: "1rem",
            }}
            className="Submitbutton"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        )}
      </div>
      <ChangeTemplateModal
        show={conditionals.badModal}
        onHide={() => setConditionals({ ...conditionals, badModal: false })}
      />
    </div>
  );
};

export default FeedbackFromStudent;
